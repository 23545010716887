import { Box, Button, Typography, useTheme } from '@mui/material';
import moment from 'moment/moment';
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { tokens } from '../../theme';
import { mockDataTeam } from '../../data/mockData';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import Header from '../../components/Header';
import { useEffect, useState } from 'react';
import PieChart from '../../components/PieChart';
import AddAlertIcon from '@mui/icons-material/AddAlert';

const Cspent = () => {
  const [newData, setNewData] = useState([]);
  const [dataTots, setDataTots] = useState([]);
  const [totCurrent, setTotCurrent] = useState(0);
  const [totM1, setTotM1] = useState(0);
  const [totM2, setTotM2] = useState(0);
  const [totM3, setTotM3] = useState(0);
  const [totOlder, setTotOlder] = useState(0);
  // const [tmpAcct, setAcct] = useState('');
  // const [tmpName, setName] = useState('');
  // const [tmpCurrent, setCurrent] = useState(0);
  // const [tmpMonth1, setMonth1] = useState(0);
  // const [tmpMonth2, setMonth2] = useState(0);
  // const [tmpMonth3, setMonth3] = useState(0);
  // const [tmpOlder, setOlder] = useState([]);
  // const [tDate, setTDate] = useState(0);

  //let newData = [];
  let tmpAcct = '';
  let tmpName = '';
  let tmpCurrent = 0;
  let tmpMonth1 = 0;
  let tmpMonth2 = 0;
  let tmpMonth3 = 0;
  let tmpOlder = 0;
  let tDate = 0;

  useEffect(() => {
    calcAOD();
  }, []);

  function calcAOD() {
    // set today to compare against payment dates
    setNewData(DATA);
    let custCount = 0;
    let todayTot = 0;

    //loop through payments and group and sum for each acct
    DATA.map((value, key) => {
      todayTot = todayTot + value.today;
      if (value.today > 0) {
        custCount = custCount + 1;
      }
    });
    let pushd = {
      id: 1,
      todayTot: todayTot,
      custCount: custCount,
    };
    console.info(pushd);
    setDataTots((dataTots) => [pushd]);
  }

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  const usdPrice = {
    type: 'number',
    width: 180,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'font-tabular-nums',
  };

  const gbpPrice = {
    type: 'number',
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'font-tabular-nums',
  };

  const colTots = [
    { field: 'id', headerName: 'ID', hide: true },
    {
      field: 'todayTot',
      headerName: 'Sales Today',
      type: 'number',
      flex: 1,
      ...gbpPrice,
    },
    {
      field: 'custCount',
      headerName: 'No Customers',
      type: 'number',
      flex: 1,
    },
  ];

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    {
      field: 'acct',
      headerName: 'Acct',
      flex: 0,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'name',
      headerName: 'Customer',
      flex: 1,
      align: 'left',
    },
    {
      field: 'thirty',
      headerName: '30 Day',
      type: 'number',
      flex: 0,
      ...usdPrice,
    },
    {
      field: 'today',
      headerName: 'Today',
      flex: 0,
      ...usdPrice,
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton /> */}

        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <Button onClick={() => {}} startIcon={<AddAlertIcon />}>
          NOTIFY ME
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleOnCellClick = (params) => {
    console.info(JSON.stringify(params.row));
  };

  return (
    <Box m="20px">
      <Box
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          style={{
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'flex-start',
            alignItems: 'left',
          }}
          sx={{
            '& .MuiDataGrid-root': { border: 'none' },
            '& .MuiDatGrid-cell': { borderBottom: 'none' },
            '& .name-column--cell': { color: colors.greenAccent[300] },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.blueAccent[700],
              borderBottom: 'none',
            },
            '& .MuiDataGrid-footerContainer': {
              backgroundColor: colors.blueAccent[700],
              borderTop: 'none',
            },
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
              outline: 'none !important',
            },
            '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <Header
            title="Customers Not Spent"
            subtitle={moment().format('Do MMMM YYYY (HH:MM:ss)')}
          />
          <Typography>TOTALS</Typography>
          <DataGrid
            disableSelectionOnClick
            sx={{ fontSize: 22 }}
            rows={dataTots}
            columns={colTots}
            hideFooter={true}
          />
        </Box>
        <Box
          style={{
            height: 300,
            flexGrow: 0,
            flex: 1.3,
            alignContent: 'center',
          }}
        >
          <PieChart />
        </Box>
      </Box>
      <Box
        m="40px 0 0 0 "
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': { border: 'none' },
          '& .MuiDatGrid-cell': { borderBottom: 'none' },
          '& .name-column--cell': { color: colors.greenAccent[300] },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: colors.blueAccent[700],
            borderTop: 'none',
          },
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          disableSelectionOnClick
          sx={{ fontSize: 20 }}
          rows={newData}
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          onCellClick={handleOnCellClick}
        />
      </Box>
    </Box>
  );
};

const DATA = [
  {
    id: 1,
    thirty: 11525.26,
    acct: 'EB001',
    name: 'EBAY SALES ACCOUNT',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 2,
    thirty: 7626.7,
    acct: 'BCC100',
    name: 'BIRMINGHAM CITY COUNCIL',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 3,
    thirty: 5506.19,
    acct: 'CSB1',
    name: 'General Trade Account',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 4,
    thirty: 4526.83,
    acct: 'NCC10',
    name: 'NORTHFIELD CAR CARE CENTRE',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 5,
    thirty: 3680.07,
    acct: 'SO10',
    name: 'Solihull Vehicle Service Cnt',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 6,
    thirty: 3653.37,
    acct: 'ANA20',
    name: 'ZT AUTOS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 7,
    thirty: 3651.01,
    acct: 'TH10',
    name: 'T.H AUTO REPAIRS',
    today: '25.60',
    branch: 'DIGBE',
  },
  {
    id: 8,
    thirty: 3575.19,
    acct: 'BER10',
    name: 'BERKELEY AUTOS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 9,
    thirty: 3172.59,
    acct: 'RSJ10',
    name: 'R S J Motors',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 10,
    thirty: 3032.44,
    acct: 'CAS20',
    name: 'C.A.S',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 11,
    thirty: 2809.42,
    acct: 'DOC30',
    name: 'CLUTCH FIT AUTO REPAIR COD',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 12,
    thirty: 2702.83,
    acct: 'CLM30',
    name: 'CLAIMS ASSIST',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 13,
    thirty: 2694.9,
    acct: 'RAD30',
    name: 'PSA Retail UK Limited',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 14,
    thirty: 2239.47,
    acct: 'SNT10',
    name: 'SNT AUTOCENTRE',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 15,
    thirty: 2038.66,
    acct: 'HAS10',
    name: 'BRIDGESTAR 001 LIMITED',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 16,
    thirty: 1958.38,
    acct: 'SCP10',
    name: 'STONEY CAR PARTS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 17,
    thirty: 1922.65,
    acct: 'MAA10',
    name: 'MA AUTOS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 18,
    thirty: 1893.81,
    acct: 'DIP10',
    name: 'DIPPY (MA200)',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 19,
    thirty: 1877.42,
    acct: 'NEW10',
    name: 'NEWBRIDGE AUTO CENTRE',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 20,
    thirty: 1702.85,
    acct: 'ILLY',
    name: 'ILLY (CAS20)',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 21,
    thirty: 1558.77,
    acct: 'OS200',
    name: 'ONE STOP AUTOS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 22,
    thirty: 1508.62,
    acct: 'BO10',
    name: 'A Bourne Motors',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 23,
    thirty: 1492.27,
    acct: 'AT20',
    name: 'Jet 200 services Ltd T/A Autotech',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 24,
    thirty: 1405,
    acct: 'ROB100',
    name: 'ROBERT CARTER',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 25,
    thirty: 1363.08,
    acct: 'AS10',
    name: 'PROSTAR 002 LIMITED',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 26,
    thirty: 1347.27,
    acct: 'TIL10',
    name: 'TILTON AUTO SERVICES',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 27,
    thirty: 1181.04,
    acct: 'S4',
    name: 'S4 STUDIOS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 28,
    thirty: 847.2,
    acct: 'EDG20',
    name: 'EDGBASTON AUTO CENTRE',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 29,
    thirty: 824,
    acct: 'HRT10',
    name: 'HEARTLANDS FLEET SERVICES LTD',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 30,
    thirty: 824,
    acct: 'BMC30',
    name: 'BARCLAY MOTOR COMPANY',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 31,
    thirty: 824,
    acct: 'RSS500',
    name: 'REGAL SERVICE STATION',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 32,
    thirty: 824,
    acct: 'PAR10',
    name: 'PARK GARAGE AUTO SALES',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 33,
    thirty: 820,
    acct: 'SMSC10',
    name: 'SMART MOT & SERVICE CENTRE',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 34,
    thirty: 820,
    acct: 'BSSC10',
    name: 'Bridge Street Service Centre',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 35,
    thirty: 800,
    acct: 'APF500',
    name: 'AUTO PRO FIT LTD',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 36,
    thirty: 790,
    acct: 'ULR10',
    name: 'ULTIMATE LANDROVER LTD',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 37,
    thirty: 763.61,
    acct: 'ACS20',
    name: 'A1 CAR SPARES',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 38,
    thirty: 762.56,
    acct: 'TY10',
    name: 'Tyre & Puncture Repair Ltd',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 39,
    thirty: 702.88,
    acct: 'DMC20',
    name: 'DIRECT MOTOR SERVICES',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 40,
    thirty: 646.75,
    acct: 'SAP10',
    name: 'SAP MOTORS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 41,
    thirty: 622.48,
    acct: 'WIG10',
    name: 'WIGORN GARAGE',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 42,
    thirty: 554.96,
    acct: 'JPM10',
    name: 'J.P.M. MOTORS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 43,
    thirty: 512,
    acct: 'FTCS10',
    name: 'FAST TRACK CAR SERVICES',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 44,
    thirty: 476.22,
    acct: 'ECS10',
    name: 'ECO CAR STORE',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 45,
    thirty: 430,
    acct: 'BEA10',
    name: 'BEARWOOD GARAGE',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 46,
    thirty: 430,
    acct: 'WMR20',
    name: 'Walsall Motor Repairs',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 47,
    thirty: 420,
    acct: 'T-F500',
    name: 'TACHO - FIT LIMITED',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 48,
    thirty: 420,
    acct: 'DA20',
    name: 'DIRECT AUTOS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 49,
    thirty: 420,
    acct: 'HTS10',
    name: 'H,T.S MOTORS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 50,
    thirty: 420,
    acct: 'JCM10',
    name: 'J.C MOTORS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 51,
    thirty: 417.65,
    acct: 'RMC10',
    name: 'RADLEY MOTOR COMPANY',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 52,
    thirty: 412,
    acct: 'SD10',
    name: 'SOUTHDELL GARAGES LIMITED',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 53,
    thirty: 402,
    acct: 'BM10',
    name: 'BROADFORD MOTORS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 54,
    thirty: 402,
    acct: 'MPG10',
    name: 'MOUNT PLEASANT GARAGE',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 55,
    thirty: 395,
    acct: 'RAS10',
    name: 'RILAND AUTO SERVICES',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 56,
    thirty: 379.09,
    acct: 'CG100',
    name: 'CRANMORE GARAGE SOLIHULL LTD',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 57,
    thirty: 378.67,
    acct: 'HA20',
    name: 'HUGH AUTOS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 58,
    thirty: 367.88,
    acct: 'IK10',
    name: 'I.K AUTO CENTRE',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 59,
    thirty: 364.93,
    acct: 'TYS10',
    name: 'TYSELEY 4X4',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 60,
    thirty: 353.65,
    acct: 'ISSY10',
    name: 'ISSY',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 61,
    thirty: 347.13,
    acct: 'WCA10',
    name: 'WEST CUSTOM AUTO LIMITED',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 62,
    thirty: 343.75,
    acct: 'TTL10',
    name: 'Talk Torque Ltd',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 63,
    thirty: 334.17,
    acct: 'CIT10',
    name: 'CITROEN SPECIALISTS (2005) LTD',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 64,
    thirty: 331.25,
    acct: 'SHER',
    name: 'SHERRINGTON WASTE MANAGMENT LTD',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 65,
    thirty: 308.75,
    acct: 'MC10',
    name: 'MOSELEY AUTO PARTS LTD',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 66,
    thirty: 276.41,
    acct: 'AUBREY',
    name: '.',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 67,
    thirty: 275.75,
    acct: 'EUR10',
    name: 'A1 SERVICES',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 68,
    thirty: 262.51,
    acct: 'TARIQ',
    name: 'EASYSTART',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 69,
    thirty: 260.29,
    acct: 'SHOAIB',
    name: 'WRENTHAM TYRES',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 70,
    thirty: 245.92,
    acct: 'CSA',
    name: 'CASH-SALE',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 71,
    thirty: 218.53,
    acct: 'CAM10',
    name: 'CAMS CARS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 72,
    thirty: 215.88,
    acct: 'TIA30',
    name: 'XCLUSIVE MOTOR SERVICES',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 73,
    thirty: 195.83,
    acct: 'KARWAN',
    name: 'KARWAN',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 74,
    thirty: 192.91,
    acct: 'BAS20',
    name: 'Brothers Auto Services Ltd',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 75,
    thirty: 160.58,
    acct: 'MB10',
    name: 'MOTORBODIES LIMITED',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 76,
    thirty: 154.16,
    acct: 'BIGJON10',
    name: 'JOHN',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 77,
    thirty: 150.69,
    acct: 'HMC10',
    name: 'HOCKLEY MOT CENTRE',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 78,
    thirty: 148,
    acct: 'BRG100',
    name: 'B.R CAR CARE CENTRE',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 79,
    thirty: 146.33,
    acct: 'CLE100',
    name: 'CLEVERSOCKS LTD',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 80,
    thirty: 141.67,
    acct: 'B10',
    name: 'B AUTOS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 81,
    thirty: 123.92,
    acct: 'BSS600',
    name: 'BRIDGE STREET SERVICE STATION',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 82,
    thirty: 119.92,
    acct: 'BRA10',
    name: 'BRAMPTONS AUTO CENTRE',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 83,
    thirty: 104.61,
    acct: 'LO20',
    name: 'Lodge Tyres',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 84,
    thirty: 103.33,
    acct: 'GKA10',
    name: 'G.K. AUTOS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 85,
    thirty: 94.49,
    acct: 'ADH10',
    name: 'ADH AUTOMOTIVE',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 86,
    thirty: 90.86,
    acct: 'WEST',
    name: 'WESTGATE BODYREPAIR',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 87,
    thirty: 86.8,
    acct: 'MR10',
    name: 'M R Auots',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 88,
    thirty: 83.33,
    acct: 'DES',
    name: "HD AUTO'S & WELDING",
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 89,
    thirty: 82.49,
    acct: 'TTL20',
    name: 'RAHEES',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 90,
    thirty: 79.24,
    acct: 'TS10',
    name: 'WINDSCREEN SPECIALISTS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 91,
    thirty: 77.67,
    acct: 'BMS500',
    name: 'BETTS MOTOR SERVICES',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 92,
    thirty: 75,
    acct: 'CLM20',
    name: 'CLAIMS ASSIST',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 93,
    thirty: 73.65,
    acct: 'CHAD10',
    name: 'CHAD (BSM)',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 94,
    thirty: 71.4,
    acct: 'CBH10',
    name: 'CARPARTS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 95,
    thirty: 70.83,
    acct: 'SAMAN',
    name: 'SAMAN',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 96,
    thirty: 53.08,
    acct: 'QS10',
    name: 'QUICK STOP SERVICE STATION LTD',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 97,
    thirty: 50,
    acct: 'MA200',
    name: 'MANZOOR AUTOS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 98,
    thirty: 48.87,
    acct: 'PIN10',
    name: 'PINDI AUTOS',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 99,
    thirty: 47.86,
    acct: 'MAT',
    name: 'MATT LO20',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 100,
    thirty: 43.73,
    acct: 'KAZ10',
    name: 'KAZ',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 101,
    thirty: 43.25,
    acct: 'ASC600',
    name: 'ABBEY SERVICE CENTRE LIMITED',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 102,
    thirty: 40.42,
    acct: 'NAS10',
    name: 'H R AUTO FLEET',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 103,
    thirty: 36.32,
    acct: 'HALL100',
    name: 'HALLS COMMERCIALS LTD',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 104,
    thirty: 35.23,
    acct: 'WEB',
    name: 'WEB CUSTOMER',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 105,
    thirty: 33.33,
    acct: 'JUNAYD',
    name: 'SJS TEXTILES',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 106,
    thirty: 32.95,
    acct: 'RAT10',
    name: 'RATCHET & SPANNER',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 107,
    thirty: 27.73,
    acct: 'AS30',
    name: 'A & S CLUTCH CENTRES',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 108,
    thirty: 25.9,
    acct: 'WAL10',
    name: 'Walker Products Inc. Europe',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 109,
    thirty: 23.98,
    acct: 'ROC10',
    name: 'NEW FLEET SERVICE LTD',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 110,
    thirty: 19.64,
    acct: 'STAFF',
    name: 'CARBITS STAFF ACCOUNT',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 111,
    thirty: 17.98,
    acct: 'DRI100',
    name: 'DRIVERS TRANSMISSION',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 112,
    thirty: 11.99,
    acct: 'REA10',
    name: 'REABRIDGE SREVICE STATION LTD',
    today: '',
    branch: 'DIGBE',
  },
  {
    id: 113,
    thirty: 11.66,
    acct: 'CAR20',
    name: 'THE CAR CLINIC',
    today: '',
    branch: 'DIGBE',
  },
];

export default Cspent;
