import van from '../../assets/van.png';


const DriverComponent = ({ apir, name }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          zIndex: 1,
          width: 50,
          height: 50,
          padding: 20,
          outline: 'none',
          borderRadius: '15px',
          // backgroundColor: 'white',
          backgroundPositionY: 'center',
          backgroundImage:
            `url(${van})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <div style={{
        // marginTop: 5,
        background:
          'linear-gradient(to bottom, #f8d038 0%,#f5ca2e 100%)',
        border: '2px solid black',
        borderRadius: '6px',
        padding: '3px',
        fontWeight: 'bold',
        fontSize: '0.9em',
        fontFamily: 'helvetica, ariel, sans-serif',
        textAlign: 'center',
        letterSpacing: '1px',
        color: 'black',
        zIndex: 500
      }}>
        {name}
      </div>
      {/* <div style={{ marginTop: 2, border: '1px solid black', width: 60, height: 10, backgroundColor: 'white', borderRadius: 15, overflow: 'hidden' }}>
        <div style={{ borderBottom: '1px solid black', fontSize: 8, fontWeight: 'bold', paddingLeft: '3%', borderTopLeftRadius: 15, borderBottomLeftRadius: 15, color: 'black', width: '80%', height: 10, background: 'linear-gradient(90deg, orange 80%, white 100%)' }}>
          30%
        </div>
      </div> */}

    </div>
  );
};

export default DriverComponent