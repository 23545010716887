import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Grid,
} from '@mui/material';
import Header from '../../components/Header';
import axios from 'axios';
import moment from 'moment';
import AddBoxIcon from '@mui/icons-material/AddBox';
import packageJson from '../../../package.json';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { makeStyles } from '@mui/styles';

import { useEffect, useState } from 'react';
import '../../index.css'; // Import the index.css file from the project root

const useStyles = makeStyles({
  vehicleContainer: {
    borderBottom: '1px solid white',
    marginTop: 5,
    transition: 'box-shadow 0.3s', // Add transition for smooth effect
    '&:hover': {
      boxShadow: '0 0 3px 3px white', // Add the desired hover effect, e.g., white glowing edge
    },
  },
});

const Vehicles = ({ cid, apir, userToken }) => {
  const [branches, setBranches] = useState([]);
  const [loadInfo, setLoadInfo] = useState([]);
  const [distanceInfo, setDistanceInfo] = useState([]);
  const [vanInfo, setVanInfo] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [formMessage, setFormMessage] = useState('');
  const [sureDelete, setSureDelete] = useState(false);
  const [formInputs, setFormInputs] = useState(selectedVehicle);
  const [selectedBranch, setSelectedBranch] = useState('');

  const [vehicle, setVehicles] = useState([]);

  const handleChange = (event) => {
    setSelectedBranch(event.target.value);
  };

  useEffect(() => {
    if (selectedBranch !== null) {
      getVehicles();
    }
  }, [selectedBranch]);

  const getBranches = async () => {
    try {
      const response = await axios.get(`https://${apir}/api/factor/branches`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      //console.info(response.data)
      if (response.data) {
        console.info('got the branches');
        setBranches(response.data);
        setSelectedBranch(response.data[0].value); // add this line
      } else {
      }
    } catch (error) {
      // Handle error
      //alert('Branch error: ' + error);
      throw error;
    }
  };

  const addVehicle = async () => {
    setSelectedVehicle({
      Reg: 'ENTER REG',
      Branch: '',
      Make: '',
      Model: '',
      Colour: '',
      FuelCardNo: '',
      FuelCardPin: '',
      FuelCardExpiry: '',
      FuelCardSupplier: '',
      LastMilage: '',
      Area: '',
      LastService: '',
      LastServiceBy: '',
      LastCheckList: '',
      LastCheckListBy: '',
      CheckListSchedule: '',
    });
  };
  const getVehicles = async () => {
    try {
      const response = await axios.get(
        `https://${apir}/api/factor/vehicles/getVehicles`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const vehiclesFromSelectedBranch = response.data.filter(
        (vehicle) => vehicle.Branch === selectedBranch
      );
      console.info(`Vehicles: ${JSON.stringify(vehiclesFromSelectedBranch)}`);
      setVehicles(vehiclesFromSelectedBranch);
    } catch (error) {
      console.error('Failed to fetch vehicles: ', error);
    }
  };

  useEffect(() => {
    getVehicles();
    getBranches();
  }, [apir]);

  useEffect(() => {
    setFormInputs({ ...selectedVehicle, Branch: selectedBranch });
  }, [selectedVehicle, selectedBranch]);

  const handlePost = (key) => (e) => {
    if (key === 'Branch') {
      setSelectedBranch(e.target.value);
      setFormInputs({ ...formInputs, [key]: e.target.value });
    } else {
      setFormInputs({ ...formInputs, [key]: e.target.value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate Reg and Branch fields
    if (!formInputs.Reg || !formInputs.Branch) {
      // These fields are required
      setFormMessage('Reg and Branch fields are required');
      return;
    }

    try {
      const response = await fetch(
        `https://${apir}/api/factor/vehicles/addVehicle/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(formInputs), // use updatedVehicle instead of selectedVehicle
        }
      );

      const data = await response.json();

      if (response.ok) {
        // Handle successful post (e.g. show notification, update UI)
        console.log('Vehicle successfully added', data);
        getVehicles();
        setSelectedVehicle(formInputs);
        setFormMessage(data);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Failed to add vehicle', error);
      // Handle error (e.g. show error message)
    }
    console.log(formInputs);
  };

  const deleteVehicle = async () => {
    // Validate Reg and Branch fields
    if (!formInputs.Reg || !formInputs.Branch) {
      // These fields are required
      setFormMessage('Reg and Branch fields are required');
      return;
    }

    try {
      const response = await fetch(
        `https://${apir}/api/factor/vehicles/deleteVehicle/`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(formInputs), // use updatedVehicle instead of selectedVehicle
        }
      );

      const data = await response.json();

      if (response.ok) {
        // Handle successful post (e.g. show notification, update UI)
        console.log('Vehicle successfully added', data);
        getVehicles();
        setSureDelete(false);
        setFormMessage(data);
        setSelectedVehicle([]);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Failed to add vehicle', error);
      // Handle error (e.g. show error message)
    }
    console.log(formInputs);
  };

  return (
    <Box m="20px">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {/* Left Side */}
        <div
          style={{
            flex: 1.2,
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 60px)',
            marginRight: 15,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                flex: 1,
              }}
            >
              <div>
                <h3 style={{ margin: 0 }}>SELECT</h3>
                <h3 style={{ margin: 0 }}>BRANCH</h3>
              </div>
              <FormControl style={{ width: '50%' }}>
                <InputLabel id="demo-simple-select-label">Location</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedBranch}
                  label="Branch"
                  onChange={handleChange}
                >
                  {branches.map((branches, index) => (
                    <MenuItem key={index} value={branches.value}>
                      {branches.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              onClick={() => addVehicle()}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                flex: 1,
              }}
            >
              <h3>ADD VEHICLE</h3>
              <AddBoxIcon style={{ fontSize: 50 }} />
            </div>
          </div>
          {/* List Vehicles */}
          <div
            style={{
              borderColor: 'white',
              borderWidth: 2,
              borderStyle: 'solid',
              padding: 10,
              borderRadius: 15,
              display: 'flex',
              flex: 1,
            }}
          >
            <div style={{ flex: 1 }}>
              {vehicle.map((vehicleItem, index) => (
                <Vehicle
                  key={index}
                  setSelectedVehicle={setSelectedVehicle}
                  selectedVehicle={selectedVehicle}
                  vehicle={vehicleItem}
                  setFormMessage={setFormMessage}
                />
              ))}
            </div>
          </div>
        </div>
        {/* Right Side */}
        <div
          style={{
            flex: 2,
            marginLeft: 15,
          }}
        >
          <div
            style={{
              borderColor: 'white',
              borderWidth: 2,
              borderStyle: 'solid',
              padding: 10,
              borderRadius: 15,
              display: 'flex',
              flex: 1,
              height: 'calc(100vh - 60px)',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <div>
              {selectedVehicle.Reg ? (
                <div>
                  <h1
                    style={{
                      background:
                        'linear-gradient(to bottom, #f8d038 0%,#f5ca2e 100%)',
                      border: '2px solid black',
                      borderRadius: '8px',
                      padding: '10px',
                      fontWeight: 'bold',
                      fontSize: '2em',
                      fontFamily: 'helvetica, ariel, sans-serif',
                      textAlign: 'center',
                      letterSpacing: '1px',
                      color: 'black',
                      maxWidth: '200px',
                    }}
                  >
                    {selectedVehicle.Reg}
                  </h1>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      {Object.keys(selectedVehicle).map((key, index) => (
                        <Grid item xs={12} sm={6} key={key}>
                          <TextField
                            id={key}
                            label={key}
                            value={
                              key === 'Branch'
                                ? selectedBranch
                                : formInputs[key] || ''
                            }
                            onChange={handlePost(key)}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <div
                      style={{
                        display: 'flex',
                        gap: 10,
                        justifyContent: 'flex-end',
                        marginTop: 30,
                      }}
                    >
                      <h4>{formMessage}</h4>
                      {sureDelete ? (
                        <>
                          <Button
                            onClick={() => setSureDelete(false)}
                            style={{ backgroundColor: 'darkblue' }}
                            variant="contained"
                            type="button"
                          >
                            CANCEL
                          </Button>
                          <Button
                            onClick={() => deleteVehicle()}
                            style={{ backgroundColor: 'darkred' }}
                            variant="contained"
                            type="button"
                          >
                            SURE?
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() => setSureDelete(true)}
                            style={{ backgroundColor: 'darkorange' }}
                            variant="contained"
                            type="button"
                          >
                            DELETE
                          </Button>
                          <Button
                            style={{ backgroundColor: 'darkgreen' }}
                            variant="contained"
                            type="submit"
                          >
                            SAVE
                          </Button>
                        </>
                      )}
                    </div>
                  </form>
                </div>
              ) : (
                <h1>NO VEHICLE SELECTED</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

const Vehicle = ({
  setFormMessage,
  vehicle,
  selectedVehicle,
  setSelectedVehicle,
}) => {
  const classes = useStyles(); // Add this line

  return (
    <div
      onClick={() => {
        setFormMessage('');
        Object.keys(selectedVehicle).length > 0 &&
        selectedVehicle.Reg === vehicle.Reg
          ? setSelectedVehicle([])
          : setSelectedVehicle(vehicle);
      }}
      className={classes.vehicleContainer}
      style={{
        borderBottom: '1px solid white',
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        backgroundColor: selectedVehicle.Reg === vehicle.Reg ? 'darkgreen' : '',
        borderRadius: 15,
      }}
    >
      {/* Top Line */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <LocalShippingIcon />
          <h1 style={{ marginLeft: 10, marginTop: 0, marginBottom: 0 }}>
            {vehicle.Reg}
          </h1>
        </div>
        <div>
          <TravelExploreIcon
            onClick={() => {}}
            style={{ color: vehicle.isActiveRun ? 'green' : 'grey' }}
          />
        </div>
      </div>
      {/* Next Line */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <h3>{vehicle.Branch}</h3>
        </div>
        <div>
          <h3>
            {vehicle.Make} - {vehicle.Model}, {vehicle.Colour}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Vehicles;
