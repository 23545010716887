import {
  Box,
  Button,
  Typography,
  useTheme,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  Popover,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
} from '@mui/material';
import moment from 'moment/moment';
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { tokens } from '../../theme';
import { mockDataTeam } from '../../data/mockData';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import Header from '../../components/Header';
import { useEffect, useState, useMemo } from 'react';
import PieChart from '../../components/PieChart';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import clsx from 'clsx';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import axios from 'axios';
import './style.css';

const VanRunsLive = ({ apir }) => {
  const [selectedBranch, setSelectedBranch] = useState('');
  const [branches, setBranches] = useState([]);
  const [driversBoardData, setDriversBoardData] = useState([]);
  const [lTime, setLTime] = useState('Getting Time...');
  const [customerData, setCustomerData] = useState([]);
  const [driversBoardLength, setDriversBoardLength] = useState(0);
  const [awaiting, setAwaiting] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState('Loading');
  const [filteredDriversBoardData, setFilteredDriversBoardData] = useState([]);

  const totalInvoices = useMemo(() => awaiting.length, [awaiting]);

  const totalUnits = useMemo(() => {
    return awaiting.reduce((acc, item) => acc + Number(item.Items), 0);
  }, [awaiting]);

  const totalLines = useMemo(() => {
    return awaiting.reduce((acc, item) => acc + Number(item.Lines), 0);
  }, [awaiting]);

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    const filteredData = sortedDriversBoardData.filter(
      (data) => data.Status === (status === 'Loading' ? 'LOADED' : 'DEPARTED')
    );
    setFilteredDriversBoardData(filteredData);
  };

  const sortedDriversBoardData = useMemo(() => {
    return [...driversBoardData].sort((a, b) => (a.SeqNo > b.SeqNo ? 1 : -1));
  }, [driversBoardData]);

  useEffect(() => {
    const filteredData = sortedDriversBoardData.filter(
      (data) =>
        data.Status === (selectedStatus === 'Loading' ? 'LOADED' : 'DEPARTED')
    );
    setFilteredDriversBoardData(filteredData);
  }, [selectedStatus, sortedDriversBoardData]);

  const getDriversBoardData = async (branch = selectedBranch) => {
    if (!branch) {
      console.warn('Selected branch is not defined');
      return;
    }

    const url = `https://${apir}/api/run/getVanRunInfo2/${branch}`;

    console.info(url);

    try {
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const sortedData = response.data.sort((a, b) => {
        const departsA = a.Departs || ''; // Handle null or undefined values
        const departsB = b.Departs || ''; // Handle null or undefined values
        return departsA.localeCompare(departsB);
      });

      // Filter out the data where Status is 'UNALL'
      const unallData = response.data.filter(
        (entry) => entry.Status === 'UNALL'
      );

      // Extract and transform the relevant data
      const awaitingData = unallData.flatMap((entry) =>
        entry.Documents.map((doc) => {
          const ihead = doc.iheads[0] || {};
          return {
            Acct: ihead.Acct || '',
            Document: doc.Document || '',
            Name: doc.customer || '',
            Value: String(ihead.Goods || ''),
            Items: String(doc.ilines.length),
            Lines: String(new Set(doc.ilines.map((iline) => iline.Part)).size),
            TimeOrdered: ihead.DateTime
              ? ihead.DateTime.split('T')[1].slice(0, 5)
              : '',
            PostCode: ihead.Pcode || '',
            DelMeth: ihead.DelMeth || '',
          };
        })
      );

      // Update the awaiting state
      setAwaiting((prevState) => [...prevState, ...awaitingData]);
      setDriversBoardData(sortedData);
    } catch (error) {
      console.error(`Failed to fetch data: ${error.message}`);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = moment().format('HH:mm:ss');
      setLTime(currentTime);

      const seconds = currentTime.split(':')[2];

      if (seconds.endsWith('0')) {
        getDriversBoardData();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [apir, selectedBranch]);

  useEffect(() => {
    getBranches();
    getCustomer();
  }, [apir]);

  const handleChange = async (event) => {
    const newBranch = event.target.value;
    setSelectedBranch(newBranch);
    await getDriversBoardData(newBranch);
  };

  const getCustomer = async () => {
    const url = `https://${apir}/api/factor/getCustomer`;

    try {
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setCustomerData(response.data);
    } catch (error) {
      console.error(`Failed to fetch customer data: ${error.message}`);
    }
  };

  const getBranches = async () => {
    try {
      const response = await axios.get(`https://${apir}/api/factor/branches`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const { data } = response;

      if (Array.isArray(data) && data.length) {
        console.info('Successfully fetched the branches');
        setBranches(data);
        setSelectedBranch(data[0].value);
      } else {
        console.warn('No branch data received');
      }
    } catch (error) {
      console.error(`Failed to fetch branches: ${error.message}`);
    }
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
      }}
    >
      <div
        style={{ margin: 20, display: 'flex', justifyContent: 'flex-start' }}
      >
        <FormControl style={{ width: '30%' }}>
          <InputLabel id="demo-simple-select-label">Location</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedBranch}
            label="Branch"
            onChange={handleChange}
          >
            {branches.map((branches, index) => (
              <MenuItem key={index} value={branches.value}>
                {branches.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ display: 'flex', flex: 2 }}>
        <div
          style={{
            height: '550px',
            overflow: 'scroll',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              flex: 1,
              marginLeft: 10,
              marginRight: 10,
              paddingLeft: 10,
              paddingRight: 10,
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <h3 style={{ textTransform: 'uppercase', flex: 1 }}>Trips</h3>
              <div
                style={{
                  flex: 0.3,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginRight: 20,
                }}
              >
                <div>
                  <h3 style={{ textTransform: 'uppercase' }}>{lTime}</h3>
                </div>
                <ToggleButtonGroup
                  value={selectedStatus}
                  exclusive
                  onChange={(event, newStatus) => handleStatusChange(newStatus)}
                  aria-label="status options"
                >
                  <ToggleButton value="Loading" aria-label="loading">
                    Loading
                  </ToggleButton>
                  <ToggleButton value="InProgress" aria-label="in-progress">
                    In Progress
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            <div
              style={{
                height: '270px',
                flexWrap: 'wrap',
                overflowY: 'hidden',
                overflowX: 'auto',
                width: '100%',
                flexDirection: 'column',
                display: 'flex',
                alignContent: 'flex-start',
              }}
              key={sortedDriversBoardData.length}
            >
              {filteredDriversBoardData.length > 0 ? (
                filteredDriversBoardData.map((data, index) => (
                  <div
                    key={index}
                    style={{
                      width: 370,
                      margin: 5,
                      border: '1px solid black',
                      borderRadius: 15,
                      padding: 2,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 5,
                        alignItems: 'center',
                        backgroundColor:
                          selectedStatus === 'Loading'
                            ? 'darkgreen'
                            : 'darkorange',
                        borderRadius: 15,
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <h3 style={{}}>Run </h3>
                        <h3 style={{ textAlign: 'right' }}>{data.VanRoute}</h3>
                      </div>
                      <div style={{ flex: 4 }}>
                        <h3
                          style={{
                            textAlign: 'right',
                            fontFamily: 'Ubuntu_400Regular_Italic',
                          }}
                        >
                          {data.Departs}
                        </h3>
                      </div>
                      <div style={{ flex: 0.5 }}>
                        <h3 style={{ textAlign: 'center', fontSize: 22 }}>+</h3>
                      </div>
                    </div>
                    {/* bottom table part */}
                    <div>
                      {/* header */}
                      <div className="tableHeader">
                        <h4 className="headerItem">ACCT</h4>
                        <h4 className="headerItem">DOCUMENT</h4>
                        <h4 className="headerItem">VALUE</h4>
                        <h4 className="headerItem">NAME</h4>
                      </div>
                      <div className="tableBody">
                        {data.Documents.length > 0 ? (
                          data.Documents.map((document, index) => (
                            <div
                              key={index}
                              draggable={true}
                              className="tableRow"
                            >
                              <p className="rowItem rowItem2">
                                {document.iheads[0]?.Acct || ''}
                              </p>
                              <p className="rowItem rowItem2">
                                {document.Document}
                              </p>
                              <p className="rowItem rowItem2">
                                £{document.iheads[0]?.Goods?.toFixed(2) || ''}
                              </p>
                              <p className="rowItemDouble rowItem2">
                                {document.customer?.substring(0, 10) || ''}
                              </p>
                            </div>
                          ))
                        ) : (
                          <p>No documents available.</p>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>Waiting for van run data.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* bottom container */}
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="invoiceHeader">
            <h3 className="invoiceTitle">
              {`INVOICES AWAITING ALLOCATION (${totalInvoices} INVOICES, ${totalUnits} UNITS, ${totalLines} LINES)`}
            </h3>
          </div>
          <div>
            {/* Header */}
            <div className="tableHeader">
              <h4 className="headerItem">ACCT</h4>
              <h4 className="headerItem">DOCUMENT</h4>
              <h4 className="headerItemDouble">NAME</h4>
              <h4 className="headerItem">VALUE</h4>
              <h4 className="headerItem">ITEMS</h4>
              <h4 className="headerItem">LINES</h4>
              <h4 className="headerItem">TIME</h4>
              <h4 className="headerItem">PCODE</h4>
              <h4 className="headerItem">DEL METH</h4>
            </div>
            <div className="tableBody">
              {awaiting.map((item, index) => (
                <div key={index} draggable={true} className="tableRow">
                  <p className="rowItem fnt">{item.Acct}</p>
                  <p className="rowItem fnt">{item.Document}</p>
                  <p className="rowItemDouble fnt">
                    {item.Name?.substring(0, 10)}
                  </p>
                  <p className="rowItem fnt">£{item.Value}</p>
                  <p className="rowItem fnt">{item.Items}</p>
                  <p className="rowItem fnt">{item.Lines}</p>
                  <p className="rowItem fnt">{item.TimeOrdered}</p>
                  <p className="rowItem fnt">{item.PostCode}</p>
                  <p className="rowItem fnt">{item.DelMeth}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="sideContainer">
          <div className="sideHeader">
            <div className="invoiceHeader">
              <h3 className="invoiceTitle">ON HOLD (18)</h3>
            </div>
            <div>
              {/* Header */}
              <div className="tableHeader">
                <h4 className="headerItemDouble">NAME</h4>
                <h4 className="headerItem">DOCUMENT</h4>

                <h4 className="headerItem">ACCOUNT</h4>
              </div>
              <div className="tableBody">
                {awaiting.map((item, index) => (
                  <div key={index} draggable={true} className="tableRow">
                    <p className="rowItemDouble fnt">
                      {item.Name?.substring(0, 10)}
                    </p>
                    <p className="rowItem fnt">{item.Document}</p>
                    <p className="rowItem fnt">{item.Acct}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default VanRunsLive;
