import {
  Box,
  Button,
  Typography,
  useTheme,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  Popover,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
} from '@mui/material';
import moment from 'moment/moment';
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { tokens } from '../../theme';
import { mockDataTeam } from '../../data/mockData';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import Header from '../../components/Header';
import { useEffect, useState, useMemo } from 'react';
import PieChart from '../../components/PieChart';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import clsx from 'clsx';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import axios from 'axios';

const Pod = ({ apir, userToken }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [alignment, setAlignment] = useState('web');
  const [newData, setNewData] = useState([]);
  const [newColumns, setNewColumns] = useState([]);
  const [toggleState, setToggleState] = useState('day');
  const [sigData, setSigData] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const openPopover = (event) => {
    setAnchor(event.currentTarget);
  };
  const [selectedName, setSelectedName] = useState('');
  const uniqueNames = [...new Set(newData.map((item) => item.Name))];

  const usdPrice = {
    type: 'number',
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'font-tabular-nums',
  };

  const columns = [
    { field: '_id', headerName: 'ID', hide: true },
    {
      field: 'Acct',
      headerName: 'Acct',
      renderCell: (params) => (
        <Tooltip title={<Typography fontSize={20}>{params.value}</Typography>}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'Name',
      headerName: 'Name',
      align: 'left',
      minWidth: 150,
      renderCell: (params) => (
        <Tooltip title={<Typography fontSize={20}>{params.value}</Typography>}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      flex: 1,
    },
    {
      field: 'Date',
      headerName: 'DateTime',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) =>
        moment(params.value).format('YYYY-MM-DD HH:mm:ss'),
      flex: 1,
    },
    {
      field: 'User',
      headerName: 'Driver',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
    },
    {
      field: 'Document',
      headerName: 'Document',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
    },
    {
      field: 'Location',
      headerName: 'Location',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <a href={`${params.value}`} target="_blank">
          CLICK TO VIEW MAP
        </a>
      ),
      flex: 1,
    },
    {
      field: 'SignatureLink',
      headerName: 'Signature',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <SignatureCell params={params} userToken={userToken} />
      ),
      flex: 1,
    },
  ];

  useEffect(() => {
    axios
      .get(`https://${apir}/api/factor/getPods`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setNewData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton /> */}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <Button onClick={() => {}} startIcon={<AddAlertIcon />}>
          NOTIFY ME
        </Button>
      </GridToolbarContainer>
    );
  }

  const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  return (
    <Box m="20px">
      <Header title="P.o.D.s" subtitle="View and Send Proof of Delivery" />
      <Autocomplete
        value={selectedName}
        onChange={(event, newValue) => {
          setSelectedName(newValue);
        }}
        options={uniqueNames}
        renderInput={(params) => (
          <TextField {...params} label="SELECT A CUSTOMER" />
        )}
      />
      <Box
        m="40px 0 0 0 "
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': { border: 'none' },
          '& .MuiDatGrid-cell': { borderBottom: 'none' },
          '& .name-column--cell': { color: colors.greenAccent[300] },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
            textAlign: 'center',
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: colors.blueAccent[700],
            borderTop: 'none',
          },
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
          '& .super-app.negative': {
            // backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: 'red',
            fontWeight: '600',
          },
          '& .super-app.positive': {
            // backgroundColor: '#d47483',
            color: 'green',
            fontWeight: '600',
          },
          '& .super-app.nothing': {
            // backgroundColor: '#d47483',
            color: 'darkgrey',
            fontWeight: '600',
            width: '100%',
            flex: 1,
            textAlign: 'center',
          },
        }}
      >
        <DataGrid
          rows={newData.filter((item) => item.Name === selectedName)}
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          componentsProps={{ toolbar: { sigData, setSigData } }}
          density="compact"
          getRowId={(row) => row._id}
          disableSelectionOnClick
          autoHeight
          pagination
        />
      </Box>
    </Box>
  );
};

const SignatureCell = ({ params, userToken }) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const res = await axios.get(params.value, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      console.info(params.value);
      const uArr = res.data;

      setImage(uArr);
    };

    fetch();
  }, [params.value]);

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <Button variant="contained" {...bindTrigger(popupState)}>
            VIEW SIGNATURE
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
          >
            <Typography sx={{ p: 2 }}>SIGNATURE</Typography>
            <div
              style={{
                flex: 1,
                textAlign: 'center',
              }}
            >
              <img src={image} />
            </div>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default Pod;
