import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styles from './style.module.scss';
import axios from 'axios';
import imgCash from '../../assets/img/cash.png';
import imgPod from '../../assets/img/sig.png';
import imgCam from '../../assets/img/cam.png';
import imgReturn from '../../assets/img/exchange.png';

const Receipts = ({ receipts, apir, userToken, setShowMap }) => {
  const [show, setShow] = useState(null);
  const [image, setImage] = useState(null);
  const [imageP, setImageP] = useState(null);

  const [hasPod, setHasPod] = useState(false);
  const [hasCash, setHasCash] = useState(false);
  const [hasParts, setHasParts] = useState(false);
  const [hasPhoto, setHasPhoto] = useState(false);

  const handleReceiptClick = (type) => {
    setShow(type);
  };

  useEffect(() => {
    const fetchData = async () => {
      console.info('trying to get the image data, if there is any')
      try {
        if (receipts && receipts.podData && receipts.podData[0] && receipts.podData[0]._id) {
          setHasPod(true);
          const res = await axios.get(`https://${apir}/api/factor/getPodSignature/${receipts.podData[0]._id}`, {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          });
          const uArr = res.data;
          setImage(uArr);
        }
        if (receipts && receipts.dropImagesData && receipts.dropImagesData[0] && receipts.dropImagesData[0]._id) {
          setHasPhoto(true);
          const res = await axios.get(`https://${apir}/api/factor/getPhoto/${receipts.dropImagesData[0]._id}`, {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          });
          const uArr = res.data;
          setImageP(uArr);
        }
        if (receipts && receipts.cashData && receipts.cashData[0] && receipts.cashData[0]._id) {
          setHasCash(true);
        }
        if (receipts && receipts.returnPartsData && receipts.returnPartsData[0] && receipts.returnPartsData[0]._id) {
          setHasParts(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [apir, receipts]);

  const getName = () => {
    if (receipts && receipts.cashData && receipts.cashData[0] && receipts.cashData[0].Name) {
      return receipts.cashData[0].Name;
    } else if (receipts && receipts.podData && receipts.podData[0] && receipts.podData[0].Name) {
      return receipts.podData[0].Name;
    } else if (receipts && receipts.returnPartsData && receipts.returnPartsData[0] && receipts.returnPartsData[0].Name) {
      return receipts.returnPartsData[0].Name;
    } else if (receipts && receipts.dropImageData && receipts.dropImageData[0] && receipts.dropImageData[0].Name) {
      return receipts.dropImageData[0].Name;
    }
    return '';
  };

  const renderedParts =
    receipts &&
    receipts.returnPartsData &&
    receipts.returnPartsData[0] &&
    receipts.returnPartsData[0].Parts && (
      <div style={{ textAlign: 'right' }}>
        <table style={{ width: '100%', fontSize: 'large' }}>
          <thead>
            <tr>
              <th>Part No</th>
              <th>Qty</th>
              <th>Reason</th>
            </tr>
          </thead>
          <tbody>
            {receipts.returnPartsData[0].Parts.map((part) => (
              <tr key={part.PartNo}>
                <td>{part.PartNo}</td>
                <td>{part.Qty}</td>
                <td>{part.Reason}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );


  const renderReceiptContent = () => {
    if (!show) {
      return <div>Select a receipt type</div>;
    } else if (show === 'cash') {
      return (
        <div>
          <div>
            <h2 style={{ marginBottom: 0, textDecoration: 'underline' }}>AMOUNT COLLECTED</h2>
            <h3>£{receipts?.cashData?.[0]?.Amount}</h3>
          </div>
          <div>
            <h2 style={{ marginBottom: 0, textDecoration: 'underline' }}>DATE / TIME</h2>
            <h3>{moment(receipts?.cashData?.[0]?.DateTime).format('LLL')}</h3>
          </div>
        </div>
      );
    } else if (show === 'parts') {
      return (
        <div>
          <div>
            <h2 style={{ marginBottom: 0, textDecoration: 'underline' }}>DATE / TIME</h2>
            <h3>{moment(receipts?.cashData?.[0]?.DateTime).format('LLL')}</h3>
          </div>
          {renderedParts}
        </div>
      );
    } else if (show === 'photo') {
      return (
        <div>
          <div>
            <h2>PHOTO TAKEN</h2>
          </div>
          <div className={styles.imageContainer}>
            <img style={{ objectFit: 'contain', width: '60%', height: '60%' }} src={`data:image/jpeg;base64,${imageP}`} alt="photo" />
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.imageContainer}>
          <div style={{ textAlign: 'right' }}>
            <h3>SIGNED BY: {receipts?.podData?.[0]?.SigName || '(no name)'}</h3>
          </div>
          <div className={styles.imageContainer}>
            <img style={{ objectFit: 'contain', width: '60%', height: '60%' }} src={image} alt="Signature" />
          </div>
          <div style={{ textAlign: 'right' }}>
            <h3>Date: {receipts?.podData?.[0]?.Date}</h3>
          </div>
        </div>
      );
    }
  };

  return (
    <div style={{ height: '100%', width: '100%', flex: 1, display: 'flex', flexDirection: 'row' }}>
      <div style={{ flex: 0.4, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <div style={{ flex: 1 }}>
          <div style={{ flex: 1, textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <h3 onClick={() => { setShowMap(true) }} >X</h3><h3>RECEIPTS</h3>
          </div>
          <hr />
        </div>
        <div
          style={{
            flex: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', borderColor: hasPod ? 'green' : '' }} className={`${styles.recLink} ${show === 'pod' ? styles.selected : ''}`} onClick={() => handleReceiptClick('pod')}>
            <h2>POD</h2>
            <img
              style={{
                maxWidth: '35%',
                maxHeight: '35%',
                borderRadius: '50%',
              }}
              src={imgPod}
              alt="return"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', borderColor: hasCash ? 'green' : '' }} className={`${styles.recLink} ${show === 'cash' ? styles.selected : ''}`} onClick={() => handleReceiptClick('cash')}>
            <h2>CASH</h2>
            <img
              style={{
                maxWidth: '35%',
                maxHeight: '35%',
                borderRadius: '50%',
              }}
              src={imgCash}
              alt="return"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', borderColor: hasParts ? 'green' : '' }} className={`${styles.recLink} ${show === 'parts' ? styles.selected : ''}`} onClick={() => handleReceiptClick('parts')}>
            <h2>PARTS</h2>
            <img
              style={{
                maxWidth: '35%',
                maxHeight: '35%',
                borderRadius: '50%',
              }}
              src={imgReturn}
              alt="return"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', borderColor: hasPhoto ? 'green' : '' }} className={`${styles.recLink} ${show === 'photo' ? styles.selected : ''}`} onClick={() => handleReceiptClick('photo')}>
            <h2>PHOTO</h2>
            <img
              style={{
                maxWidth: '35%',
                maxHeight: '35%',
                borderRadius: '50%',
              }}
              src={imgCam}
              alt="return"
            />
          </div>
        </div>
      </div>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%', textAlign: 'right' }}>
        <h2 style={{ margin: 15 }}>{getName()}</h2>
        <div style={{ flex: 1, margin: 10, height: '100%' }}>{renderReceiptContent()}</div>
        <div style={{ flex: 0.2 }}></div>
      </div>
    </div>
  );
};

export default Receipts;
