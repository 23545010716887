import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';

import Header from '../../components/Header';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import 'html5-device-mockups/dist/device-mockups.min.css';
import { IPad, IPhoneX } from 'react-device-mockups';

import ReactDOM from 'react-dom';
import 'react-quill/dist/quill.snow.css'; // import styles
import ReactQuill, { Quill } from 'react-quill';
// import Quill from 'quill';

import '../../index.css';
import '../../devices.css';
import ImageResize from 'quill-image-resize-module-react';
import { set } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

var Font = Quill.import('formats/font');
Font.whitelist = [
  'Ubuntu',
  'Roboto',
  'Raleway',
  'Montserrat',
  'Lato',
  'Courier New',
  'Tahoma',
];
Quill.register(Font, true);
Quill.register('modules/imageResize', ImageResize);

const News = ({ userObj, cid, apir, userToken, setUserToken }) => {
  const [editorState, setEditorState] = useState('');
  const [seeEditor, setSeeEditor] = useState(false);
  const [seePreview, setSeePreview] = useState(true);
  const quillRef = useRef(null); // Create a Ref
  const [backgroundImageURL, setBackgroundImageURL] = useState('none'); // Initial value as 'none'
  const [bulletins, setBulletins] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [deviceData, setDeviceData] = useState([]);

  function groupData(deviceData) {
    const groupedData = {};

    deviceData.forEach((item) => {
      const branch = item.Branch;
      const acct = `${item.Acct} - ${item.Name}`;
      const user = {
        name: item.User,
        date: item.DateTime,
        deviceId: item.DeviceId,
        BulletinId: selectedId,
      };

      if (!groupedData[branch]) {
        groupedData[branch] = {};
      }

      if (!groupedData[branch][acct]) {
        groupedData[branch][acct] = [];
      }

      groupedData[branch][acct].push(user);
    });

    return groupedData;
  }

  function DeviceList({ deviceData }) {
    const groupedData = groupData(deviceData);

    const toggleUser = (user) => {
      if (selectedUsers.some((u) => u.deviceId === user.deviceId)) {
        setSelectedUsers(
          selectedUsers.filter((u) => u.deviceId !== user.deviceId)
        );
      } else {
        setSelectedUsers([...selectedUsers, user]);
      }
    };

    const toggleCustomer = (users) => {
      if (
        users.every((user) =>
          selectedUsers.some((u) => u.deviceId === user.deviceId)
        )
      ) {
        setSelectedUsers(
          selectedUsers.filter(
            (u) => !users.some((user) => u.deviceId === user.deviceId)
          )
        );
      } else {
        const combinedUsers = [...selectedUsers, ...users];
        const uniqueUsers = combinedUsers.reduce((unique, user) => {
          if (!unique.find((u) => u.deviceId === user.deviceId)) {
            unique.push(user);
          }
          return unique;
        }, []);
        setSelectedUsers(uniqueUsers);
      }
    };

    const toggleBranch = (branchUsers) => {
      if (
        branchUsers.every((user) =>
          selectedUsers.some((u) => u.deviceId === user.deviceId)
        )
      ) {
        setSelectedUsers(
          selectedUsers.filter(
            (u) => !branchUsers.some((user) => u.deviceId === user.deviceId)
          )
        );
      } else {
        const combinedUsers = [...selectedUsers, ...branchUsers];
        const uniqueUsers = combinedUsers.reduce((unique, user) => {
          if (!unique.find((u) => u.deviceId === user.deviceId)) {
            unique.push(user);
          }
          return unique;
        }, []);
        setSelectedUsers(uniqueUsers);
      }
    };

    const isBranchChecked = (branchUsers) => {
      return branchUsers.every((user) =>
        selectedUsers.find((u) => u.deviceId === user.deviceId)
      );
    };

    const isCustomerChecked = (users) => {
      return users.every((user) =>
        selectedUsers.find((u) => u.deviceId === user.deviceId)
      );
    };

    return (
      <div style={{ overflow: 'auto' }}>
        {Object.keys(groupedData).map((branch, index) => {
          const branchUsers = Object.values(groupedData[branch]).flat();
          return (
            <div style={{ width: '100%' }} key={index}>
              {/* Branch level */}
              <div
                style={{
                  display: 'flex',
                  backgroundColor: 'white',
                  flexDirection: 'row',
                  borderRadius: '10px',
                  alignItems: 'center',
                }}
              >
                <div style={{}}>
                  <h2
                    style={{
                      margin: 0,
                      color: 'black',
                      borderRadius: '10px',
                      paddingLeft: 10,
                    }}
                  >
                    {branch}
                  </h2>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Checkbox
                    sx={{
                      color: 'black',
                      '&.Mui-checked': {
                        color: 'black',
                      },
                    }}
                    checked={isBranchChecked(branchUsers) || false}
                    onChange={() => toggleBranch(branchUsers)}
                  />
                  <h5 style={{ color: 'black' }}>SELECT ALL</h5>
                </div>
              </div>
              {/* Customer and User level */}
              {Object.keys(groupedData[branch]).map((acct) => {
                const users = groupedData[branch][acct];
                return (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {/* Customer level */}
                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: 'solid 1px white',
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <h3 style={{ fontStyle: 'italic' }}>{acct}</h3>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Checkbox
                          sx={{
                            color: 'white',
                            [`&, &.${checkboxClasses.checked}`]: {
                              color: 'white',
                            },
                          }}
                          checked={isCustomerChecked(users) || false}
                          onChange={() => toggleCustomer(users)}
                        />
                        <h5>SELECT ALL</h5>
                      </div>
                    </div>
                    {/* User level */}
                    {users.map((user) => (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Checkbox
                          sx={{
                            color: 'white',
                            [`&, &.${checkboxClasses.checked}`]: {
                              color: 'white',
                            },
                          }}
                          checked={Boolean(
                            selectedUsers.find(
                              (u) => u.deviceId === user.deviceId
                            )
                          )}
                          onChange={() => toggleUser(user)}
                        />
                        <h4>
                          {user.name} -{' '}
                          {new Date(user.date).toLocaleDateString()}
                        </h4>
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  const uniqueSelectedUsers = selectedUsers.reduce((unique, user) => {
    if (!unique.find((u) => u.deviceId === user.deviceId)) {
      unique.push(user);
    }
    return unique;
  }, []);

  useEffect(() => {
    console.log('Selected users changed:', selectedUsers);
  }, [selectedUsers]);

  const clearForm = () => {
    // Ask for confirmation
    if (window.confirm('Are you sure you want to clear all data?')) {
      // Clear the data if the user confirms
      setEditorState(''); // Assuming you use a state to manage editorState
      setBulletinData({
        bulletinName: '',
        bulletinNotes: '',
        bulletinExpiry: '',
        // Include other fields that need to be cleared
      });
      setBackgroundImageURL(''); // If you have a specific initial value, set it here
    }
  };

  const backgroundOptions = [
    { label: 'None', value: 'none' },
    {
      label: 'Background 1',
      value:
        'https://www.toptal.com/designers/subtlepatterns/uploads/moroccan-flower.png',
    },
    {
      label: 'Background 2',
      value:
        'https://img.freepik.com/premium-vector/cars-road-objects-traffic-signs-automobile-symbols-seamless-pattern-doodle-style-vector_192280-755.jpg',
    },
    {
      label: 'Background 3',
      value:
        'https://static.vecteezy.com/system/resources/previews/005/447/461/non_2x/auto-parts-seamless-background-this-background-can-be-used-as-wallpapers-for-a-garage-car-service-or-as-a-fabric-print-vector.jpg',
    },
    // Add more options as needed
  ];

  const handleBackgroundChange = (event) => {
    setBackgroundImageURL(event.target.value);
  };

  useEffect(() => {
    setEditorState(editorState);
  }, [editorState]);

  const modules = {
    imageResize: {
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },

    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons

      [{ list: 'ordered' }, { list: 'bullet' }],

      // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['link', 'image', 'video'],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }],
      [{ font: Font.whitelist }],
    ],
  };

  const handleEditorChange = (content, delta, source, editor) => {
    setEditorState(editor.getHTML());
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBulletinData({ ...bulletinData, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    setBulletinData({ ...bulletinData, bulletinActive: event.target.checked });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `https://${apir}/api/factor/news/addBulletin`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            Content: editorState,
            Name: bulletinData.bulletinName,
            Notes: bulletinData.bulletinNotes,
            Expiry: bulletinData.bulletinExpiry,
            DateTime: bulletinData.bulletinDate,
            User: userObj.Username,
            BulletinGuid: uuidv4(),
          }),
        }
      );
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const sendNotify = async (event) => {
    if (!uniqueSelectedUsers.length > 0) {
      alert('No users selected');
      return;
    }
    try {
      const response = await fetch(
        `https://${apir}/api/factor/news/sendBulletin`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(selectedUsers),
        }
      );
      const data = await response.json();
      if (data === 'done') {
        setSelectedUsers([]);
        alert('Notification sent');
        setSeePreview(true);
      }
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBulletins = async () => {
    try {
      const response = await fetch(
        `https://${apir}/api/factor/news/getBulletin`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      console.error(data);
      setBulletins(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDevices = async () => {
    try {
      const response = await fetch(
        `https://${apir}/api/factor/getdevicesAndCustomers`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setDeviceData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBulletins();
    fetchDevices();
  }, []);

  const getBulletins = async () => {
    try {
      const response = await fetch(
        `https://${apir}/api/factor/news/getBulletin`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      // Check if the response is successful
      if (response.ok) {
        const bulletins = await response.json();
        console.log(bulletins);
        return bulletins;
      } else {
        console.error(`Failed to fetch bulletins: ${response.statusText}`);
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const deleteBulletin = async (id, name) => {
    // Ask the user if they are sure they want to delete the bulletin
    const isConfirmed = window.confirm(
      `Are you sure you want to delete the bulletin "${name}"? This will permanently delete the bulletin from all devices previously sent to.`
    );

    if (isConfirmed) {
      try {
        const response = await fetch(
          `https://${apir}/api/factor/news/deleteBulletin/${id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response.status === 200) {
          console.log('Bulletin deleted successfully');
          fetchBulletins();
          // Optionally, you can refresh the bulletins list here
        } else {
          console.error('Error deleting bulletin');
        }
      } catch (error) {
        console.error('There was an error deleting the bulletin:', error);
      }
    } else {
      console.log('Bulletin deletion canceled');
    }
  };

  const [bulletinData, setBulletinData] = useState({
    bulletinName: '',
    bulletinNotes: '',
    bulletinDate: new Date().toISOString().split('T')[0],
    bulletinExpiry: null,
    bulletinActive: true,
    bulletinContent: '',
  });

  return (
    <Box m="20px">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {/* Left Side */}
        <div
          style={{
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 60px)',
            marginRight: 15,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 10,
              alignItems: 'center',
            }}
          >
            <div
              onClick={() => {
                setSeeEditor(!seeEditor);
                setSeePreview(seePreview);
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                flex: 1,
                alignItems: 'center',
              }}
            >
              <h3 style={{ margin: 0 }}>
                SHOW {seeEditor ? 'LIST' : 'EDITOR'}
              </h3>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                flex: 1,
                alignItems: 'center',
              }}
            >
              {/* <div>
                <h3 style={{ margin: 0 }}>SELECT BRANCH</h3>
              </div> */}
            </div>
            <div
              onClick={() => {
                setEditorState('');
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                flex: 1,
              }}
            >
              <h3>CLEAR BULLETIN</h3>
              <AddBoxIcon style={{ fontSize: 50 }} />
            </div>
          </div>
          {/* List Vehicles */}
          <div
            style={{
              borderColor: 'white',
              borderWidth: 2,
              borderStyle: 'solid',
              padding: 10,
              borderRadius: 15,
              display: 'flex',
              flex: 1,

              maxHeight: '-webkit-fill-available',
              overflow: 'auto',
            }}
          >
            <div style={{ flex: 1 }}>
              <div
                style={{
                  // border: '1px solid black',
                  minHeight: '6em',
                  cursor: 'text',
                  height: '100%',
                  display: seeEditor ? 'flex' : 'none',
                  flexDirection: seeEditor ? 'column' : '',
                }}
              >
                <ReactQuill
                  ref={quillRef}
                  value={editorState}
                  onChange={handleEditorChange}
                  modules={modules}
                  placeholder="Add content here"
                />
                <div style={{ marginTop: 30 }} id="saveForm">
                  {/* <Button
                    onClick={() =>
                      setEditorState(
                        (editorState) =>
                          editorState +
                          '<div style="text-align:center"><button type="button" style="background-color:darkgreen;border-radius:15px">dd</button></div>'
                      )
                    }
                    variant="contained"
                  >
                    <Typography
                      style={{
                        backgroundColor: 'darkgreen',
                        borderRadius: 15,
                        padding: 10,
                      }}
                      variant="h4"
                    >
                      Add Enquire
                    </Typography>
                  </Button> */}
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      {/* <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel>Background</InputLabel>
                          <Select
                            value={backgroundImageURL}
                            onChange={handleBackgroundChange}
                          >
                            {backgroundOptions.map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid> */}
                      <Grid item xs={12}>
                        <TextField
                          name="bulletinName"
                          label="Bulletin Name"
                          value={bulletinData.bulletinName}
                          onChange={handleInputChange}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="bulletinNotes"
                          label="Bulletin Notes"
                          value={bulletinData.bulletinNotes}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="bulletinDate"
                          label="Bulletin Date"
                          type="date"
                          value={bulletinData.bulletinDate}
                          InputLabelProps={{ shrink: true }}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="bulletinExpiry"
                          label="Bulletin Expiry"
                          type="date"
                          value={bulletinData.bulletinExpiry}
                          onChange={handleInputChange}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          required
                        />
                      </Grid>
                      {/* <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={bulletinData.bulletinActive}
                              onChange={handleCheckboxChange}
                              name="bulletinActive"
                            />
                          }
                          label="Bulletin Active"
                        />
                      </Grid> */}
                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{
                              borderRadius: 15,
                              backgroundColor: 'darkgreen',
                              fontWeight: 'bold',
                            }}
                          >
                            SAVE
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            style={{
                              borderRadius: 15,
                              backgroundColor: 'darkred',
                              color: 'white',
                              fontWeight: 'bold',
                            }}
                            onClick={clearForm}
                          >
                            CANCEL
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </div>
                {/* <div>{editorState}</div> */}
              </div>
              {/* list of bulletins */}
              <div
                style={{
                  // border: '1px solid black',
                  minHeight: '6em',
                  cursor: 'text',
                  height: '100%',
                  display: !seeEditor ? 'flex' : 'none',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ width: '100%', marginLeft: 10 }}>
                    <button
                      style={{
                        backgroundColor: 'darkorange',
                        fontWeight: 'bold',
                        color: 'black',
                        padding: 5,
                      }}
                      onClick={fetchBulletins}
                    >
                      Refresh
                    </button>
                    {/* Refresh the bulletins when the button is clicked */}
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {bulletins.map((bulletin, index) => (
                      <div
                        onClick={() => {
                          setEditorState(bulletin.Content);
                          setSelectedId(bulletin.BulletinGuid);
                        }}
                        key={index}
                        style={{
                          cursor: 'grab',
                          margin: '10px',
                          border: '1px solid #ccc',
                          padding: '10px',
                          userSelect: 'none',
                          height: '300px',
                          width: '250px',
                          overflow: 'auto',
                          borderRadius: 15,
                          backgroundColor:
                            bulletin.BulletinGuid == selectedId
                              ? 'darkblue'
                              : '',
                        }}
                      >
                        <h2>{bulletin.Name}</h2>
                        <h3>{bulletin.Notes}</h3>
                        <p>
                          Date:{' '}
                          {new Date(bulletin.DateTime).toLocaleDateString()}
                        </p>
                        <p>
                          Expiry:{' '}
                          {new Date(bulletin.Expiry).toLocaleDateString()}
                        </p>
                        <p>User: {bulletin.User}</p>
                        <div>
                          <Grid container item xs={12} spacing={2}>
                            <Grid item xs={6}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={{
                                  borderRadius: 15,
                                  backgroundColor: 'darkgreen',
                                  fontWeight: 'bold',
                                }}
                                onClick={() => {
                                  setSelectedId(bulletin.BulletinGuid);
                                  setSeePreview(false);
                                }}
                              >
                                SEND
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                style={{
                                  borderRadius: 15,
                                  backgroundColor: 'darkred',
                                  color: 'white',
                                  fontWeight: 'bold',
                                }}
                                onClick={() =>
                                  deleteBulletin(
                                    bulletin.BulletinGuid,
                                    bulletin.Name
                                  )
                                } // Pass the bulletin name as well
                              >
                                DELETE
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Right Side */}
        {seePreview ? (
          <div
            style={{
              flex: 1.2,
              marginLeft: 15,
            }}
          >
            <div
              style={{
                // borderColor: 'white',
                // borderWidth: 2,
                // borderStyle: 'solid',
                padding: 10,
                borderRadius: 15,
                display: 'flex',
                flex: 1,
                height: 'calc(100vh - 60px)',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <div className="device device-iphone-x">
                <div class="device-frame">
                  <div
                    className="device-content"
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'white',
                      borderRadius: 15,
                      borderStyle: 'none',
                      paddingTop: 40,
                    }}
                  >
                    <iframe
                      style={{
                        width: '100%',
                        height: '100%',
                        borderStyle: 'none',
                      }}
                      srcDoc={`<html><head><link rel="stylesheet" type="text/css" href="https://cdn.quilljs.com/1.3.6/quill.snow.css" onload="this.onload=null;this.rel='stylesheet'">
                  <link rel="stylesheet" type="text/css" href="index.css" onload="this.onload=null;this.rel='stylesheet'">
                  </head><body style="background-size: 60%;background-repeat: repeat;background-image: url('${backgroundImageURL}');" class="ql-editor">${editorState}</body></html>`}
                    />
                  </div>
                </div>
                <div className="device-stripe"></div>
                <div className="device-header"></div>
                <div className="device-sensors"></div>
                <div className="device-btns"></div>
                <div className="device-power"></div>
              </div>
              {/* <div>{editorState}</div> */}
            </div>
          </div>
        ) : (
          <div
            style={{
              flex: 1.2,
              marginLeft: 15,
              height: 'calc(100vh - 60px)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <DeviceList deviceData={deviceData} />

            <div>
              {/* {JSON.stringify(selectedUsers)} */}
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                style={{
                  borderRadius: 15,
                  backgroundColor: 'darkred',
                  color: 'white',
                  fontWeight: 'bold',
                  margin: 5,
                }}
                onClick={() => {
                  setSeePreview(true);
                  setSelectedUsers([]);
                }} // Pass the bulletin name as well
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                style={{
                  borderRadius: 15,
                  backgroundColor: 'darkgreen',
                  color: 'white',
                  fontWeight: 'bold',
                  margin: 5,
                }}
                onClick={() => {
                  sendNotify();
                }} // Pass the bulletin name as well
              >
                SEND
              </Button>
            </div>
          </div>
        )}
      </div>
    </Box>
  );
};

export default News;
