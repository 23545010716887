import {
  Box,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment/moment';
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { tokens } from '../../theme';
import { mockDataTeam } from '../../data/mockData';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import Header from '../../components/Header';
import { useEffect, useState } from 'react';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import clsx from 'clsx';

const Pgdrift = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [alignment, setAlignment] = useState('web');
  const [newData, setNewData] = useState([]);
  const [newColumns, setNewColumns] = useState([]);
  const [toggleState, setToggleState] = useState('day');

  function addPerc(mydata) {
    console.info('running day data');
    setNewData([]);
    //loop through payments and group and sum for each acct
    mydata.map((value, key) => {
      //console.info(key);
      {
        let mMon = value.Monday || 0;
        let mTue = value.Tuesday || 0;
        let mWed = value.Wednesday || 0;
        let mThu = value.Thursday || 0;
        let mFri = value.Friday || 0;
        let mSat = value.Saturday || 0;
        let mSun = value.Sunday || 0;

        //create tmp array with collected info
        let data2 = {
          id: key,
          PG: value.PG,
          Description: value.Description,
          Monday: mMon,
          Tuesday: mTue,
          TueP: formatDiff(mMon, mTue),
          Wednesday: mWed,
          WedP: formatDiff(mTue, mWed),
          Thursday: mThu,
          ThuP: formatDiff(mWed, mThu),
          Friday: mFri,
          FriP: formatDiff(mThu, mFri),
          Saturday: mSat,
          SatP: formatDiff(mFri, mSat),
          Sunday: mSun,
          SunP: formatDiff(mSat, mSun),

          //   TueP: formatPerc(((mTue - mMon) / mMon) * 100),
          //   WedP: formatPerc(((mWed - mTue) / mTue) * 100),
          //   ThuP: formatPerc(((mThu - mWed) / mWed) * 100),
          //   FriP: formatPerc(((mFri - mThu) / mThu) * 100),
          //   SatP: formatPerc(((mSat - mFri) / mFri) * 100),
          //   SunP: formatPerc(((mSun - mSat) / mSat) * 100),
        };
        //console.info(data2);
        //push tmp array into new array result
        setNewData((newData) => [...newData, data2]);
      }
      return null;
    });
  }

  function addPercYear(mydata) {
    setNewData([]);
    //loop through payments and group and sum for each acct
    mydata.map((value, key) => {
      //console.info(key);
      {
        let mJan = value.January || 0;
        let mFeb = value.February || 0;
        let mMar = value.March || 0;
        let mApr = value.April || 0;
        let mMay = value.May || 0;
        let mJun = value.June || 0;
        let mJul = value.July || 0;
        let mAug = value.August || 0;
        let mSep = value.September || 0;
        let mOct = value.October || 0;
        let mNov = value.November || 0;
        let mDec = value.December || 0;

        //create tmp array with collected info
        let data2 = {
          id: key,
          PG: value.PG,
          Description: value.Description,
          January: mJan,
          February: mFeb,
          FebP: formatDiff(mJan, mFeb),
          March: mMar,
          MarP: formatDiff(mFeb, mMar),
          April: mApr,
          AprP: formatDiff(mMar, mApr),
          May: mMay,
          MayP: formatDiff(mApr, mMay),
          June: mJun,
          JunP: formatDiff(mMay, mJun),
          July: mJul,
          JulP: formatDiff(mJun, mJul),
          August: mAug,
          AugP: formatDiff(mJul, mAug),
          September: mSep,
          SepP: formatDiff(mAug, mSep),
          October: mOct,
          OctP: formatDiff(mSep, mOct),
          November: mNov,
          NovP: formatDiff(mOct, mNov),
          December: mDec,
          DecP: formatDiff(mNov, mDec),

          //   TueP: formatPerc(((mTue - mMon) / mMon) * 100),
          //   WedP: formatPerc(((mWed - mTue) / mTue) * 100),
          //   ThuP: formatPerc(((mThu - mWed) / mWed) * 100),
          //   FriP: formatPerc(((mFri - mThu) / mThu) * 100),
          //   SatP: formatPerc(((mSat - mFri) / mFri) * 100),
          //   SunP: formatPerc(((mSun - mSat) / mSat) * 100),
        };
        //console.info(data2);
        //push tmp array into new array result
        setNewData((newData) => [...newData, data2]);
      }
      return null;
    });
  }

  function formatPerc(valueRet) {
    if (!isFinite(valueRet)) {
      return '-';
    } else if (isNaN(valueRet)) {
      return '-';
    } else if (valueRet > 0) {
      return '▲';
    } else if (valueRet < 0) {
      return '▼';
    }
  }

  function formatDiff(day1, day2) {
    if (day1 > day2) {
      return '▼';
    } else if (day1 < day2) {
      return '▲';
    } else {
      return '-';
    }
  }

  useEffect(() => {
    setNewColumns(columns);
    addPerc(dDATA);
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton /> */}

        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <Button onClick={() => {}} startIcon={<AddAlertIcon />}>
          NOTIFY ME
        </Button>
      </GridToolbarContainer>
    );
  }

  const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  const usdPrice = {
    type: 'number',
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'font-tabular-nums',
  };

  const columnsYear = [
    { field: 'id', headerName: 'ID', hide: true },
    {
      field: 'PG',
      headerName: 'PG',
      renderCell: (params) => (
        <Tooltip title={<Typography fontSize={20}>{params.value}</Typography>}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'Description',
      headerName: 'Description',
      align: 'left',
      minWidth: 150,
      renderCell: (params) => (
        <Tooltip title={<Typography fontSize={20}>{params.value}</Typography>}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'January',
      headerName: 'Jan',
      headerAlign: 'center',
      type: 'number',
      ...usdPrice,
    },
    {
      field: 'February',
      headerName: 'Feb',
      headerAlign: 'center',
      ...usdPrice,
    },
    {
      field: 'FebP',
      headerName: '%',
      headerAlign: 'center',
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
    {
      field: 'March',
      headerName: 'Mar',
      headerAlign: 'center',
      ...usdPrice,
    },
    {
      field: 'MarP',
      headerName: '%',
      headerAlign: 'center',
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
    {
      field: 'April',
      headerName: 'Apr',
      headerAlign: 'center',
      ...usdPrice,
    },
    {
      field: 'AprP',
      headerName: '%',
      headerAlign: 'center',
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
    {
      field: 'May',
      headerName: 'May',
      headerAlign: 'center',
      ...usdPrice,
    },
    {
      field: 'MayP',
      headerName: '%',
      headerAlign: 'center',
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
    {
      field: 'June',
      headerName: 'Jun',
      headerAlign: 'center',
      ...usdPrice,
    },
    {
      field: 'JunP',
      headerName: '%',
      headerAlign: 'center',
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
    {
      field: 'July',
      headerName: 'Jul',
      headerAlign: 'center',
      ...usdPrice,
    },
    {
      field: 'JulP',
      headerName: '%',
      headerAlign: 'center',
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
    {
      field: 'August',
      headerName: 'Aug',
      headerAlign: 'center',
      ...usdPrice,
    },
    {
      field: 'AugP',
      headerName: '%',
      headerAlign: 'center',
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
    {
      field: 'September',
      headerName: 'Sep',
      headerAlign: 'center',
      ...usdPrice,
    },
    {
      field: 'SepP',
      headerName: '%',
      headerAlign: 'center',
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
    {
      field: 'October',
      headerName: 'Oct',
      headerAlign: 'center',
      ...usdPrice,
    },
    {
      field: 'OctP',
      headerName: '%',
      headerAlign: 'center',
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
    {
      field: 'November',
      headerName: 'Nov',
      headerAlign: 'center',
      ...usdPrice,
    },
    {
      field: 'NovP',
      headerName: '%',
      headerAlign: 'center',
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
    {
      field: 'December',
      headerName: 'Dec',
      headerAlign: 'center',
      ...usdPrice,
    },
    {
      field: 'DecP',
      headerName: '%',
      headerAlign: 'center',
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
  ];

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    {
      field: 'PG',
      headerName: 'PG',
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={<Typography fontSize={20}>{params.value}</Typography>}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'Description',
      headerName: 'Description',
      flex: 3,
      align: 'left',
      renderCell: (params) => (
        <Tooltip title={<Typography fontSize={20}>{params.value}</Typography>}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'Monday',
      headerName: 'Monday',
      type: 'number',
      flex: 1.5,
      ...usdPrice,
    },
    {
      field: 'Tuesday',
      headerName: 'Tuesday',
      flex: 1.5,
      ...usdPrice,
    },
    {
      field: 'TueP',
      headerName: '%',
      flex: 1,
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
    {
      field: 'Wednesday',
      headerName: 'Wednesday',
      flex: 1.5,
      ...usdPrice,
    },
    {
      field: 'WedP',
      headerName: '%',
      flex: 1,
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
    {
      field: 'Thursday',
      headerName: 'Thursday',
      flex: 1.5,
      ...usdPrice,
    },
    {
      field: 'ThuP',
      headerName: '%',
      flex: 1,
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
    {
      field: 'Friday',
      headerName: 'Friday',
      flex: 1.5,
      ...usdPrice,
    },
    {
      field: 'FriP',
      headerName: '%',
      flex: 1,
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
    {
      field: 'Saturday',
      headerName: 'Saturday',
      flex: 1.5,
      ...usdPrice,
    },
    {
      field: 'SatP',
      headerName: '%',
      flex: 1,
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
    {
      field: 'Sunday',
      headerName: 'Sunday',
      flex: 1.5,
      ...usdPrice,
    },
    {
      field: 'SunP',
      headerName: '%',
      flex: 1,
      align: 'center',
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return '';
      //     }

      //     const valueFormatted = params.value;
      //     return `${valueFormatted} %`;
      //   },
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          negative: params.value == '▼',
          positive: params.value == '▲',
          nothing: params.value == '-',
        });
      },
    },
  ];

  function handleChange(e) {
    let period = e.target.value;
    setToggleState(period);
    if (period == 'day') {
      addPerc(dDATA);
      setNewColumns(columns);
    } else if (period == 'month') {
      addPercYear(yDATA);
      setNewColumns(columnsYear);
    }
    console.info(e.target.value);
  }

  return (
    <Box m="20px">
      <Header title="Product Group Drift" subtitle="By Day or Month" />
      <ToggleButtonGroup
        color="primary"
        exclusive
        aria-label="Platform"
        onChange={handleChange}
      >
        <ToggleButton
          style={
            toggleState == 'day'
              ? { backgroundColor: colors.primary[300] }
              : null
          }
          value="day"
        >
          DAY
        </ToggleButton>
        <ToggleButton
          style={
            toggleState == 'month'
              ? { backgroundColor: colors.primary[300] }
              : null
          }
          value="month"
        >
          MONTH
        </ToggleButton>
      </ToggleButtonGroup>
      <Box
        m="40px 0 0 0 "
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': { border: 'none' },
          '& .MuiDatGrid-cell': { borderBottom: 'none' },
          '& .name-column--cell': { color: colors.greenAccent[300] },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
            textAlign: 'center',
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: colors.blueAccent[700],
            borderTop: 'none',
          },
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
          '& .super-app.negative': {
            // backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: 'red',
            fontWeight: '600',
          },
          '& .super-app.positive': {
            // backgroundColor: '#d47483',
            color: 'green',
            fontWeight: '600',
          },
          '& .super-app.nothing': {
            // backgroundColor: '#d47483',
            color: 'darkgrey',
            fontWeight: '600',
            width: '100%',
            flex: 1,
            textAlign: 'center',
          },
        }}
      >
        <DataGrid
          disableSelectionOnClick
          sx={{ fontSize: 15 }}
          rows={newData}
          columns={newColumns}
          components={{ Toolbar: CustomToolbar }}
          initialState={{ pinnedColumns: { left: ['Acct'] } }}
        />
      </Box>
    </Box>
  );
};

const yDATA = [
  {
    id: 1,
    PG: '',
    Description: '',
    January: null,
    February: null,
    March: null,
    April: null,
    May: null,
    June: null,
    July: null,
    August: 60,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 2,
    PG: 'HB',
    Description: '',
    January: null,
    February: null,
    March: null,
    April: null,
    May: null,
    June: null,
    July: null,
    August: 16.89,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 3,
    PG: 'NEWULT',
    Description: '',
    January: null,
    February: null,
    March: null,
    April: null,
    May: null,
    June: null,
    July: 15,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 4,
    PG: 'ADD',
    Description: '*** Block Import ***',
    January: null,
    February: null,
    March: null,
    April: 10.04,
    May: 2.28,
    June: 103.31,
    July: 1.25,
    August: 9.82,
    September: 5.41,
    October: 43.83,
    November: '',
    December: '',
  },
  {
    id: 5,
    PG: 'FAI',
    Description: '*** Block Import ***',
    January: null,
    February: null,
    March: null,
    April: null,
    May: null,
    June: null,
    July: null,
    August: null,
    September: null,
    October: 587.45,
    November: '',
    December: '',
  },
  {
    id: 6,
    PG: 'NAPA',
    Description: '*** Block Import ***',
    January: null,
    February: null,
    March: null,
    April: null,
    May: null,
    June: 4227.74,
    July: 6378.26,
    August: 7154.38,
    September: 7564.51,
    October: 6265.9,
    November: '',
    December: '',
  },
  {
    id: 7,
    PG: 'AC',
    Description: 'ACCESSORIES',
    January: 3030.51,
    February: 3175.42,
    March: 3523.9,
    April: 4378.36,
    May: 5867.28,
    June: 5639.78,
    July: 3762.66,
    August: 5762.12,
    September: 4625.94,
    October: 4333.89,
    November: '',
    December: '',
  },
  {
    id: 8,
    PG: 'AC',
    Description: 'ACCESSORIES ETC',
    January: 3030.51,
    February: 3175.42,
    March: 3523.9,
    April: 4378.36,
    May: 5867.28,
    June: 5639.78,
    July: 3762.66,
    August: 5762.12,
    September: 4625.94,
    October: 4333.89,
    November: '',
    December: '',
  },
  {
    id: 9,
    PG: 'ADB',
    Description: 'ADBLUE',
    January: null,
    February: 25,
    March: null,
    April: null,
    May: null,
    June: null,
    July: null,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 10,
    PG: 'AD',
    Description: 'Additives',
    January: 748.6,
    February: 634.8,
    March: 1937.02,
    April: 1124.82,
    May: 1600.42,
    June: 1677.22,
    July: 659.38,
    August: 1234.06,
    September: 452.02,
    October: 391.84,
    November: '',
    December: '',
  },
  {
    id: 11,
    PG: 'ADL',
    Description: 'ADL BLUEPRINT PRODUCTS',
    January: 437.4,
    February: 163.32,
    March: 217.22,
    April: 437.39,
    May: 261.41,
    June: 291.28,
    July: 380.88,
    August: 678.75,
    September: 377.81,
    October: 290.14,
    November: '',
    December: '',
  },
  {
    id: 12,
    PG: 'AIR',
    Description: 'AIR CON PRODUCTS',
    January: null,
    February: null,
    March: null,
    April: 87.5,
    May: 99.99,
    June: null,
    July: null,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 13,
    PG: 'AF',
    Description: 'AIR FILTER',
    January: 1009.7,
    February: 17759.66,
    March: 1573.02,
    April: 1418.12,
    May: 1429.98,
    June: 744.82,
    July: 664.04,
    August: 455.04,
    September: 500.58,
    October: 274.72,
    November: '',
    December: '',
  },
  {
    id: 14,
    PG: 'ALT',
    Description: 'ALTERNATOR',
    January: 566.69,
    February: 526,
    March: 392.6,
    April: 199.74,
    May: 297.46,
    June: null,
    July: 92.95,
    August: 112.5,
    September: 187.5,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 15,
    PG: 'ALT',
    Description: 'ALTERNATORS',
    January: 566.69,
    February: 526,
    March: 392.6,
    April: 199.74,
    May: 297.46,
    June: null,
    July: 92.95,
    August: 112.5,
    September: 187.5,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 16,
    PG: 'ANF',
    Description: 'ANTIFREEZE',
    January: 1550.52,
    February: 1547.37,
    March: 2062.59,
    April: 3517.55,
    May: 1104.61,
    June: 4682.39,
    July: 3541.82,
    August: 4254.46,
    September: 2204.39,
    October: 1349.65,
    November: '',
    December: '',
  },
  {
    id: 17,
    PG: 'ADB',
    Description: 'APEC ADJUSTER',
    January: null,
    February: 25,
    March: null,
    April: null,
    May: null,
    June: null,
    July: null,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 18,
    PG: 'MCY',
    Description: 'APEC MCYLS',
    January: null,
    February: null,
    March: 81.44,
    April: 90,
    May: null,
    June: 158.33,
    July: null,
    August: 37.5,
    September: null,
    October: 20.44,
    November: '',
    December: '',
  },
  {
    id: 19,
    PG: 'ATF',
    Description: 'ATF-CVT-DCT-PS',
    January: null,
    February: 47.26,
    March: 52.72,
    April: 13.29,
    May: 55.95,
    June: 4.26,
    July: null,
    August: 54.39,
    September: 10.68,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 20,
    PG: 'BB',
    Description: 'BATTERIES',
    January: 463.17,
    February: 718.81,
    March: 304.64,
    April: 796.83,
    May: 608.22,
    June: 603.22,
    July: 829.84,
    August: 1124.58,
    September: 2164.24,
    October: 1567.49,
    November: '',
    December: '',
  },
  {
    id: 21,
    PG: 'BM',
    Description: 'Blueman cust',
    January: null,
    February: 70,
    March: null,
    April: null,
    May: null,
    June: null,
    July: null,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 22,
    PG: 'BM',
    Description: 'Blueman customers',
    January: null,
    February: 70,
    March: null,
    April: null,
    May: null,
    June: null,
    July: null,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 23,
    PG: 'BK',
    Description: 'Boot kits',
    January: 485.99,
    February: 767.35,
    March: 727.97,
    April: 632.51,
    May: 1165.3,
    June: 406.93,
    July: 458.6,
    August: 787.26,
    September: 1267.27,
    October: 820.28,
    November: '',
    December: '',
  },
  {
    id: 24,
    PG: 'BOSCH',
    Description: 'BOSCH',
    January: 13681.38,
    February: 13954.69,
    March: 15125.55,
    April: 12326.64,
    May: 13221.86,
    June: 9676.56,
    July: 11554.07,
    August: 13088.61,
    September: 12707.83,
    October: 9958.61,
    November: '',
    December: '',
  },
  {
    id: 25,
    PG: 'RAD',
    Description: 'BOSCH RADS',
    January: 92.19,
    February: 239.87,
    March: 405.64,
    April: 116.66,
    May: 273,
    June: 360.75,
    July: 211.46,
    August: 254.33,
    September: 832.69,
    October: 276.24,
    November: '',
    December: '',
  },
  {
    id: 26,
    PG: 'BOSCH',
    Description: 'BOSCH WIPER BLADE',
    January: 13681.38,
    February: 13954.69,
    March: 15125.55,
    April: 12326.64,
    May: 13221.86,
    June: 9676.56,
    July: 11554.07,
    August: 13088.61,
    September: 12707.83,
    October: 9958.61,
    November: '',
    December: '',
  },
  {
    id: 27,
    PG: 'BC',
    Description: 'BRAKE CALIPERS',
    January: 1992.88,
    February: 4382.7,
    March: 2857.86,
    April: 2729.76,
    May: 938.04,
    June: 2174.76,
    July: 1797.26,
    August: 2453.7,
    September: 1028.2,
    October: 1259.62,
    November: '',
    December: '',
  },
  {
    id: 28,
    PG: 'BD',
    Description: 'BRAKE DISC/DRUMS',
    January: 3598.46,
    February: 4421.96,
    March: 6877.45,
    April: 5224.25,
    May: 4005.15,
    June: 3433.8,
    July: 4865.18,
    August: 5065.47,
    September: 4944.83,
    October: 2809.06,
    November: '',
    December: '',
  },
  {
    id: 29,
    PG: 'BD',
    Description: 'BRAKE DISCS',
    January: 3598.46,
    February: 4421.96,
    March: 6877.45,
    April: 5224.25,
    May: 4005.15,
    June: 3433.8,
    July: 4865.18,
    August: 5065.47,
    September: 4944.83,
    October: 2809.06,
    November: '',
    December: '',
  },
  {
    id: 30,
    PG: 'BW',
    Description: 'BRAKE FITTING KITS ETC',
    January: 323.09,
    February: 311.37,
    March: 392.72,
    April: 334.13,
    May: 314.24,
    June: 269.63,
    July: 888.8,
    August: 637.13,
    September: 889.45,
    October: 337.98,
    November: '',
    December: '',
  },
  {
    id: 31,
    PG: 'BF',
    Description: 'BRAKE FLUID',
    January: 167.82,
    February: 83.66,
    March: 336.18,
    April: 156.44,
    May: 366.14,
    June: 125.64,
    July: 50.78,
    August: 118.6,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 32,
    PG: 'BH',
    Description: 'BRAKE HOSE',
    January: 62.14,
    February: 40.58,
    March: 57.54,
    April: 14.3,
    May: null,
    June: 45.65,
    July: 24.5,
    August: 44.46,
    September: 90.86,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 33,
    PG: 'BW',
    Description: 'BRAKE KITS ETC',
    January: 323.09,
    February: 311.37,
    March: 392.72,
    April: 334.13,
    May: 314.24,
    June: 269.63,
    July: 888.8,
    August: 637.13,
    September: 889.45,
    October: 337.98,
    November: '',
    December: '',
  },
  {
    id: 34,
    PG: 'BM',
    Description: 'BRAKE MASTER CYL',
    January: null,
    February: 70,
    March: null,
    April: null,
    May: null,
    June: null,
    July: null,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 35,
    PG: 'BP',
    Description: 'BRAKE PAD',
    January: 4061.91,
    February: 4567.91,
    March: 6013.34,
    April: 4064.89,
    May: 4329.07,
    June: 3907.16,
    July: 4892.01,
    August: 5145.03,
    September: 4413.41,
    October: 2656.61,
    November: '',
    December: '',
  },
  {
    id: 36,
    PG: 'BP',
    Description: 'BRAKE PADS',
    January: 4061.91,
    February: 4567.91,
    March: 6013.34,
    April: 4064.89,
    May: 4329.07,
    June: 3907.16,
    July: 4892.01,
    August: 5145.03,
    September: 4413.41,
    October: 2656.61,
    November: '',
    December: '',
  },
  {
    id: 37,
    PG: 'BS',
    Description: 'BRAKE SHOE',
    January: 76.37,
    February: 82.17,
    March: 103.98,
    April: 88.07,
    May: 16.67,
    June: 50.04,
    July: null,
    August: 64.75,
    September: 55.09,
    October: 13.33,
    November: '',
    December: '',
  },
  {
    id: 38,
    PG: 'BS',
    Description: 'BRAKE SHOES',
    January: 76.37,
    February: 82.17,
    March: 103.98,
    April: 88.07,
    May: 16.67,
    June: 50.04,
    July: null,
    August: 64.75,
    September: 55.09,
    October: 13.33,
    November: '',
    December: '',
  },
  {
    id: 39,
    PG: 'BU',
    Description: 'BULB',
    January: 93.75,
    February: 90.46,
    March: 106.06,
    April: 61.58,
    May: 108.43,
    June: 35,
    July: null,
    August: null,
    September: 38.47,
    October: 3.99,
    November: '',
    December: '',
  },
  {
    id: 40,
    PG: 'BU',
    Description: 'bulbs',
    January: 93.75,
    February: 90.46,
    March: 106.06,
    April: 61.58,
    May: 108.43,
    June: 35,
    July: null,
    August: null,
    September: 38.47,
    October: 3.99,
    November: '',
    December: '',
  },
  {
    id: 41,
    PG: 'B',
    Description: 'BUYING PARTS',
    January: 13588.93,
    February: 12041.11,
    March: 11929.38,
    April: 12161.7,
    May: 13562.56,
    June: 12992.66,
    July: 10806.6,
    August: 8762.87,
    September: 10400.4,
    October: 11926.21,
    November: '',
    December: '',
  },
  {
    id: 42,
    PG: 'CJ',
    Description: 'C V JOINTS',
    January: 291.2,
    February: 91.66,
    March: 167.73,
    April: 137.94,
    May: 215.7,
    June: 113.95,
    July: 306.9,
    August: 20.83,
    September: 124.59,
    October: 50,
    November: '',
    December: '',
  },
  {
    id: 43,
    PG: 'CV',
    Description: 'C.V.BOOT',
    January: 184.91,
    February: 161.28,
    March: 408.12,
    April: 193.21,
    May: 141.61,
    June: 168.08,
    July: 132.45,
    August: 177.46,
    September: 227.52,
    October: 135.28,
    November: '',
    December: '',
  },
  {
    id: 44,
    PG: 'CF',
    Description: 'CABIN FILTER',
    January: 136.98,
    February: 140.28,
    March: 208.54,
    April: 185.94,
    May: 128.31,
    June: 13.55,
    July: 5.21,
    August: 27.62,
    September: 30.7,
    October: 12.72,
    November: '',
    December: '',
  },
  {
    id: 45,
    PG: 'CF',
    Description: 'CABIN FILTERS',
    January: 136.98,
    February: 140.28,
    March: 208.54,
    April: 185.94,
    May: 128.31,
    June: 13.55,
    July: 5.21,
    August: 27.62,
    September: 30.7,
    October: 12.72,
    November: '',
    December: '',
  },
  {
    id: 46,
    PG: 'CT',
    Description: 'CABLE TIES',
    January: null,
    February: null,
    March: 13.99,
    April: null,
    May: null,
    June: 3.99,
    July: null,
    August: null,
    September: 33.6,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 47,
    PG: 'CAM',
    Description: 'CAMBIARE',
    January: 152.34,
    February: 101.96,
    March: 147.82,
    April: 75.54,
    May: null,
    June: 51.58,
    July: null,
    August: 227.36,
    September: 117.32,
    October: 204.04,
    November: '',
    December: '',
  },
  {
    id: 48,
    PG: 'CC',
    Description: 'CAR CARE',
    January: 21.03,
    February: 536.71,
    March: 1155.23,
    April: 95.77,
    May: 105.56,
    June: 13.46,
    July: 25.22,
    August: 5.02,
    September: null,
    October: 56.86,
    November: '',
    December: '',
  },
  {
    id: 49,
    PG: 'CH',
    Description: 'CHEMICALS',
    January: null,
    February: 33.88,
    March: 12.49,
    April: null,
    May: null,
    June: null,
    July: null,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 50,
    PG: 'CK',
    Description: 'CLUTCH KIT',
    January: 1410.9,
    February: 1456.32,
    March: 658.68,
    April: 863.5,
    May: 1185.49,
    June: 1308.52,
    July: 1279,
    August: 699.49,
    September: 604.16,
    October: 706.44,
    November: '',
    December: '',
  },
  {
    id: 51,
    PG: 'CS',
    Description: 'COIL SPRINGS',
    January: 1063.1,
    February: 1371.27,
    March: 979.9,
    April: 761.98,
    May: 1118.84,
    June: 242.6,
    July: 269.82,
    August: 187.62,
    September: 257.52,
    October: 226.36,
    November: '',
    December: '',
  },
  {
    id: 52,
    PG: 'CT',
    Description: 'Colin customers',
    January: null,
    February: null,
    March: 13.99,
    April: null,
    May: null,
    June: 3.99,
    July: null,
    August: null,
    September: 33.6,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 53,
    PG: 'CT',
    Description: 'Colin Turner',
    January: null,
    February: null,
    March: 13.99,
    April: null,
    May: null,
    June: 3.99,
    July: null,
    August: null,
    September: 33.6,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 54,
    PG: 'CVP',
    Description: 'COMMERCIAL PARTS',
    January: null,
    February: null,
    March: null,
    April: null,
    May: null,
    June: 26.66,
    July: null,
    August: 29.17,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 55,
    PG: 'CO',
    Description: 'CONSUMABLES',
    January: null,
    February: null,
    March: null,
    April: 424.17,
    May: 1288.86,
    June: 358.28,
    July: 33.56,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 56,
    PG: 'CO',
    Description: 'Cooling',
    January: null,
    February: null,
    March: null,
    April: 424.17,
    May: 1288.86,
    June: 358.28,
    July: 33.56,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 57,
    PG: 'COOL',
    Description: 'COOLING',
    January: 175.33,
    February: null,
    March: 19.36,
    April: 23.53,
    May: null,
    June: 2.49,
    July: null,
    August: 57.77,
    September: 14.44,
    October: 32.92,
    November: '',
    December: '',
  },
  {
    id: 58,
    PG: 'CV',
    Description: 'CV Joints',
    January: 184.91,
    February: 161.28,
    March: 408.12,
    April: 193.21,
    May: 141.61,
    June: 168.08,
    July: 132.45,
    August: 177.46,
    September: 227.52,
    October: 135.28,
    November: '',
    December: '',
  },
  {
    id: 59,
    PG: 'DS',
    Description: 'DRIVE SHAFT',
    January: 372.29,
    February: 264.78,
    March: 603,
    April: 430.7,
    May: 357.22,
    June: 1399.12,
    July: 528.97,
    August: 459.12,
    September: 241.67,
    October: 204.17,
    November: '',
    December: '',
  },
  {
    id: 60,
    PG: 'DS',
    Description: 'Drive shafts',
    January: 372.29,
    February: 264.78,
    March: 603,
    April: 430.7,
    May: 357.22,
    June: 1399.12,
    July: 528.97,
    August: 459.12,
    September: 241.67,
    October: 204.17,
    November: '',
    December: '',
  },
  {
    id: 61,
    PG: 'EL',
    Description: 'ELECTRICAL',
    January: 330,
    February: null,
    March: null,
    April: 324.28,
    May: null,
    June: null,
    July: null,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 62,
    PG: 'EA',
    Description: 'ELECTRICAL ACCESSORIES',
    January: null,
    February: null,
    March: 252.9,
    April: null,
    May: null,
    June: null,
    July: null,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 63,
    PG: 'EM',
    Description: 'ENGINE MANAGEMENT',
    January: 780.74,
    February: 568.82,
    March: 851.14,
    April: 1064.71,
    May: 1403.54,
    June: 403.9,
    July: 401.82,
    August: 483.63,
    September: 412.8,
    October: 261.24,
    November: '',
    December: '',
  },
  {
    id: 64,
    PG: 'EM',
    Description: 'ENGINE MANGEMENT',
    January: 780.74,
    February: 568.82,
    March: 851.14,
    April: 1064.71,
    May: 1403.54,
    June: 403.9,
    July: 401.82,
    August: 483.63,
    September: 412.8,
    October: 261.24,
    November: '',
    December: '',
  },
  {
    id: 65,
    PG: 'EN',
    Description: 'ENGINE PARTS',
    January: 151.34,
    February: 179.36,
    March: 66.66,
    April: 80,
    May: 1787.52,
    June: 82.9,
    July: 728.12,
    August: 49.48,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 66,
    PG: 'EX',
    Description: 'EXHASTS',
    January: 250,
    February: 507.16,
    March: 173.3,
    April: 612.47,
    May: 115.59,
    June: 323,
    July: 8,
    August: null,
    September: 2.66,
    October: 221.04,
    November: '',
    December: '',
  },
  {
    id: 67,
    PG: 'EX',
    Description: 'Exhausts',
    January: 250,
    February: 507.16,
    March: 173.3,
    April: 612.47,
    May: 115.59,
    June: 323,
    July: 8,
    August: null,
    September: 2.66,
    October: 221.04,
    November: '',
    December: '',
  },
  {
    id: 68,
    PG: 'FAH',
    Description: 'FAHREN',
    January: null,
    February: 58.4,
    March: 75.92,
    April: null,
    May: 29.17,
    June: null,
    July: null,
    August: null,
    September: 45.83,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 69,
    PG: 'FB',
    Description: 'FAN BELT',
    January: 212.03,
    February: 133.22,
    March: 90.57,
    April: 53.38,
    May: 38.76,
    June: 264.06,
    July: 225.82,
    August: 198.98,
    September: 224.85,
    October: 132.13,
    November: '',
    December: '',
  },
  {
    id: 70,
    PG: 'FB',
    Description: 'FAN BELTS',
    January: 212.03,
    February: 133.22,
    March: 90.57,
    April: 53.38,
    May: 38.76,
    June: 264.06,
    July: 225.82,
    August: 198.98,
    September: 224.85,
    October: 132.13,
    November: '',
    December: '',
  },
  {
    id: 71,
    PG: 'FT',
    Description: 'FASTENERS',
    January: 23.37,
    February: 40.67,
    March: 49.56,
    April: 49.66,
    May: 74.05,
    June: 57.32,
    July: 48.31,
    August: 15.81,
    September: 40.1,
    October: 13.31,
    November: '',
    December: '',
  },
  {
    id: 72,
    PG: 'FEBI',
    Description: 'FEBI PARTS',
    January: 156.36,
    February: 139.62,
    March: 271.54,
    April: null,
    May: 111.7,
    June: 29.54,
    July: 97.3,
    August: 228.41,
    September: 151.45,
    October: 23.34,
    November: '',
    December: '',
  },
  {
    id: 73,
    PG: 'FT',
    Description: 'Fitting Tools & Kits',
    January: 23.37,
    February: 40.67,
    March: 49.56,
    April: 49.66,
    May: 74.05,
    June: 57.32,
    July: 48.31,
    August: 15.81,
    September: 40.1,
    October: 13.31,
    November: '',
    December: '',
  },
  {
    id: 74,
    PG: 'FF',
    Description: 'FUEL FILTER',
    January: 408.3,
    February: 296.36,
    March: 340.2,
    April: 314.89,
    May: 414.8,
    June: 237.36,
    July: 139.32,
    August: 164.4,
    September: 131.8,
    October: 116.96,
    November: '',
    December: '',
  },
  {
    id: 75,
    PG: 'FF',
    Description: 'FUEL FILTERS',
    January: 408.3,
    February: 296.36,
    March: 340.2,
    April: 314.89,
    May: 414.8,
    June: 237.36,
    July: 139.32,
    August: 164.4,
    September: 131.8,
    October: 116.96,
    November: '',
    December: '',
  },
  {
    id: 76,
    PG: 'GAS',
    Description: 'GASKETS',
    January: 954.81,
    February: 1112.52,
    March: 1411.15,
    April: 1344.3,
    May: 1301.81,
    June: 1102.62,
    July: 885.36,
    August: 1161.83,
    September: 1620.38,
    October: 585.87,
    November: '',
    December: '',
  },
  {
    id: 77,
    PG: 'GAT',
    Description: 'GATES',
    January: 580.63,
    February: 1036.54,
    March: 1101.94,
    April: 574.49,
    May: 1144.1,
    June: 635.26,
    July: 1021.14,
    August: 490.39,
    September: 518.12,
    October: 432.81,
    November: '',
    December: '',
  },
  {
    id: 78,
    PG: 'GAT',
    Description: 'GATES PRODUCTS',
    January: 580.63,
    February: 1036.54,
    March: 1101.94,
    April: 574.49,
    May: 1144.1,
    June: 635.26,
    July: 1021.14,
    August: 490.39,
    September: 518.12,
    October: 432.81,
    November: '',
    December: '',
  },
  {
    id: 79,
    PG: 'GOIL',
    Description: 'GEAR OILS',
    January: 4.16,
    February: null,
    March: null,
    April: null,
    May: null,
    June: null,
    July: null,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 80,
    PG: 'GLV',
    Description: 'gloves',
    January: 771.38,
    February: 604.8,
    March: 656.4,
    April: 681.86,
    May: 580.88,
    June: 195.24,
    July: 203.3,
    August: 445.44,
    September: 398.96,
    October: 163.5,
    November: '',
    December: '',
  },
  {
    id: 81,
    PG: 'MISC',
    Description: 'GROUP MISC',
    January: 4.79,
    February: 7.99,
    March: 0,
    April: 0,
    May: 64.09,
    June: 10.33,
    July: null,
    August: null,
    September: null,
    October: 92.5,
    November: '',
    December: '',
  },
  {
    id: 82,
    PG: 'VB',
    Description: 'HANDBRAKE CABLE',
    January: 60.67,
    February: 258.95,
    March: 245.4,
    April: 292.71,
    May: 199.91,
    June: 83.72,
    July: 137.13,
    August: 207.06,
    September: 228.45,
    October: 95.62,
    November: '',
    December: '',
  },
  {
    id: 83,
    PG: 'INA',
    Description: 'INA PRODUCTS',
    January: 113.78,
    February: 109.42,
    March: 283.13,
    April: 187.3,
    May: 419.1,
    June: 308.07,
    July: 455.04,
    August: 171.03,
    September: 267.47,
    October: 191.75,
    November: '',
    December: '',
  },
  {
    id: 84,
    PG: 'LUB',
    Description: 'LUBRICANTS',
    January: null,
    February: 75.96,
    March: null,
    April: 38.32,
    May: null,
    June: null,
    July: 43.36,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 85,
    PG: 'LUK',
    Description: 'LUK',
    January: 1810.93,
    February: 2338.13,
    March: 3780.14,
    April: 2843.44,
    May: 2728.99,
    June: 3354.52,
    July: 1546.19,
    August: 3677.48,
    September: 2128.61,
    October: 1431.35,
    November: '',
    December: '',
  },
  {
    id: 86,
    PG: 'LUK',
    Description: 'LUK CLUTCHES',
    January: 1810.93,
    February: 2338.13,
    March: 3780.14,
    April: 2843.44,
    May: 2728.99,
    June: 3354.52,
    July: 1546.19,
    August: 3677.48,
    September: 2128.61,
    October: 1431.35,
    November: '',
    December: '',
  },
  {
    id: 87,
    PG: 'MAM',
    Description: 'M.A.M SOFTWARE',
    January: 132.26,
    February: 250.57,
    March: 419.98,
    April: 104.82,
    May: 72.74,
    June: 643.34,
    July: 143.67,
    August: 271.27,
    September: 65.66,
    October: 50.81,
    November: '',
    December: '',
  },
  {
    id: 88,
    PG: 'MAH',
    Description: 'MAHLE FILTERS',
    January: 1618.34,
    February: 1207.3,
    March: 1813.72,
    April: 1675.6,
    May: 1374.65,
    June: 1342.06,
    July: 1301.02,
    August: 1304.09,
    September: 1509.72,
    October: 1245.03,
    November: '',
    December: '',
  },
  {
    id: 89,
    PG: 'MAM',
    Description: 'MAM IMPORT',
    January: 132.26,
    February: 250.57,
    March: 419.98,
    April: 104.82,
    May: 72.74,
    June: 643.34,
    July: 143.67,
    August: 271.27,
    September: 65.66,
    October: 50.81,
    November: '',
    December: '',
  },
  {
    id: 90,
    PG: 'MANN',
    Description: 'MANN & HUMMEL',
    January: null,
    February: null,
    March: null,
    April: null,
    May: null,
    June: null,
    July: null,
    August: null,
    September: 10.29,
    October: 6.86,
    November: '',
    December: '',
  },
  {
    id: 91,
    PG: 'MCY',
    Description: 'MASTER CYL',
    January: null,
    February: null,
    March: 81.44,
    April: 90,
    May: null,
    June: 158.33,
    July: null,
    August: 37.5,
    September: null,
    October: 20.44,
    November: '',
    December: '',
  },
  {
    id: 92,
    PG: 'MAY',
    Description: 'MAYPOLE',
    January: null,
    February: null,
    March: 10.42,
    April: null,
    May: null,
    June: null,
    July: null,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 93,
    PG: 'TMD',
    Description: 'MINTEX',
    January: null,
    February: null,
    March: null,
    April: null,
    May: null,
    June: null,
    July: 18.31,
    August: null,
    September: 28.1,
    October: 26.67,
    November: '',
    December: '',
  },
  {
    id: 94,
    PG: 'RC',
    Description: 'MISC',
    January: 13.5,
    February: 11.4,
    March: 25.7,
    April: 4.17,
    May: 41.73,
    June: 30.88,
    July: 24.92,
    August: 16.83,
    September: null,
    October: 6.67,
    November: '',
    December: '',
  },
  {
    id: 95,
    PG: 'MIS',
    Description: 'MISC PRODUCTS',
    January: 1269.15,
    February: 1598.96,
    March: 1787.23,
    April: 1797.94,
    May: 2088.08,
    June: 2104.97,
    July: 1617.46,
    August: 2308.84,
    September: 1622.39,
    October: 1494.34,
    November: '',
    December: '',
  },
  {
    id: 96,
    PG: 'MT',
    Description: 'MOUNTINGS',
    January: 573.38,
    February: 628.8,
    March: 1036.14,
    April: 235.54,
    May: 778.76,
    June: 110,
    July: 246.52,
    August: 143.04,
    September: 284.4,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 97,
    PG: 'NP',
    Description: 'NUMBER PLATES',
    January: 535.12,
    February: 527.27,
    March: 639.35,
    April: 489.07,
    May: 515.02,
    June: 485.86,
    July: 446.09,
    August: 235.97,
    September: 555.92,
    October: 183.35,
    November: '',
    December: '',
  },
  {
    id: 98,
    PG: 'FL',
    Description: 'OIL FILTER',
    January: 363.39,
    February: 501.68,
    March: 799.05,
    April: 452.98,
    May: 517.62,
    June: 326.08,
    July: 109.1,
    August: 98.87,
    September: 151.31,
    October: 49.63,
    November: '',
    December: '',
  },
  {
    id: 99,
    PG: 'FL',
    Description: 'OIL FILTERS',
    January: 363.39,
    February: 501.68,
    March: 799.05,
    April: 452.98,
    May: 517.62,
    June: 326.08,
    July: 109.1,
    August: 98.87,
    September: 151.31,
    October: 49.63,
    November: '',
    December: '',
  },
  {
    id: 100,
    PG: 'OIL',
    Description: 'OILS',
    January: 45209.54,
    February: 41261.4,
    March: 57347.04,
    April: 44971.48,
    May: 45393.36,
    June: 34210.14,
    July: 44218.9,
    August: 45218.96,
    September: 48394.24,
    October: 48066.48,
    November: '',
    December: '',
  },
  {
    id: 101,
    PG: 'RC',
    Description: 'RAD CAPS',
    January: 13.5,
    February: 11.4,
    March: 25.7,
    April: 4.17,
    May: 41.73,
    June: 30.88,
    July: 24.92,
    August: 16.83,
    September: null,
    October: 6.67,
    November: '',
    December: '',
  },
  {
    id: 102,
    PG: 'RAD',
    Description: 'RADIATORS',
    January: 92.19,
    February: 239.87,
    March: 405.64,
    April: 116.66,
    May: 273,
    June: 360.75,
    July: 211.46,
    August: 254.33,
    September: 832.69,
    October: 276.24,
    November: '',
    December: '',
  },
  {
    id: 103,
    PG: 'SA',
    Description: 'SHOCK ABSORBER',
    January: 1217.25,
    February: 1373.09,
    March: 2185.67,
    April: 1019.29,
    May: 1345.4,
    June: 574.51,
    July: 155.09,
    August: 63.59,
    September: 52.81,
    October: 172.66,
    November: '',
    December: '',
  },
  {
    id: 104,
    PG: 'SCY',
    Description: 'SLAVE CYL',
    January: 25,
    February: 37.5,
    March: 66.67,
    April: 30,
    May: 85.4,
    June: null,
    July: 55,
    August: null,
    September: 29.17,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 105,
    PG: 'SP',
    Description: 'SPARK PLUG',
    January: 45.64,
    February: 67.28,
    March: 241.04,
    April: 157.92,
    May: 88.2,
    June: 130.45,
    July: 145.77,
    August: 348.97,
    September: 397.3,
    October: 131.49,
    November: '',
    December: '',
  },
  {
    id: 106,
    PG: 'SMP',
    Description: 'STANDARD MOTOR PARTS',
    January: 543.89,
    February: 609.73,
    March: 389.99,
    April: 653.75,
    May: 1201.1,
    June: 940.16,
    July: 1386.52,
    August: 269.9,
    September: 1850.09,
    October: 287.82,
    November: '',
    December: '',
  },
  {
    id: 107,
    PG: 'STA',
    Description: 'STARTER MOTORS',
    January: 405,
    February: 634.48,
    March: 878.06,
    April: 169.9,
    May: 573.34,
    June: 1534.62,
    July: 606.54,
    August: null,
    September: null,
    October: 470.66,
    November: '',
    December: '',
  },
  {
    id: 108,
    PG: 'SS',
    Description: 'STEERING & SUSPENSION',
    January: 3269.26,
    February: 3626.09,
    March: 3575.62,
    April: 2644.81,
    May: 2685.57,
    June: 847.51,
    July: 543.02,
    August: 1173.57,
    September: 304.72,
    October: 469.74,
    November: '',
    December: '',
  },
  {
    id: 109,
    PG: 'SS',
    Description: 'STEERING PART',
    January: 3269.26,
    February: 3626.09,
    March: 3575.62,
    April: 2644.81,
    May: 2685.57,
    June: 847.51,
    July: 543.02,
    August: 1173.57,
    September: 304.72,
    October: 469.74,
    November: '',
    December: '',
  },
  {
    id: 110,
    PG: 'SP',
    Description: 'Steering pumps',
    January: 45.64,
    February: 67.28,
    March: 241.04,
    April: 157.92,
    May: 88.2,
    June: 130.45,
    July: 145.77,
    August: 348.97,
    September: 397.3,
    October: 131.49,
    November: '',
    December: '',
  },
  {
    id: 111,
    PG: 'SR',
    Description: 'Steering Racks',
    January: 137.5,
    February: 321.99,
    March: 80,
    April: null,
    May: 218.4,
    June: 155,
    July: 189.99,
    August: null,
    September: 229.99,
    October: 304.67,
    November: '',
    December: '',
  },
  {
    id: 112,
    PG: 'SR',
    Description: 'STEERING RACKS & PUMPS',
    January: 137.5,
    February: 321.99,
    March: 80,
    April: null,
    May: 218.4,
    June: 155,
    July: 189.99,
    August: null,
    September: 229.99,
    October: 304.67,
    November: '',
    December: '',
  },
  {
    id: 113,
    PG: 'TEN',
    Description: 'TENSIONERS',
    January: null,
    February: null,
    March: null,
    April: null,
    May: null,
    June: null,
    July: null,
    August: 327.2,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 114,
    PG: 'TH',
    Description: 'THERMOSTATS',
    January: 84.96,
    February: 66.66,
    March: 304.52,
    April: 221.4,
    May: 231.68,
    June: 170.8,
    July: 150.52,
    August: 460.3,
    September: 634.26,
    October: 761.24,
    November: '',
    December: '',
  },
  {
    id: 115,
    PG: 'TK',
    Description: 'THERMOSTATS KITS',
    January: 142.5,
    February: 12.75,
    March: 51.66,
    April: 8.55,
    May: 154.51,
    June: 45.83,
    July: null,
    August: 12.5,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 116,
    PG: 'TBK',
    Description: 'TIMING BELT KITS',
    January: 207.08,
    February: null,
    March: 543.66,
    April: 526.66,
    May: 473.4,
    June: 730,
    July: 472.68,
    August: 466.88,
    September: 1147.94,
    October: 308.34,
    November: '',
    December: '',
  },
  {
    id: 117,
    PG: 'TETE',
    Description: 'TJ FILTER',
    January: 68.82,
    February: 87.25,
    March: 44.59,
    April: 14.87,
    May: 45.82,
    June: 28.3,
    July: 46.93,
    August: 39.37,
    September: 51.56,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 118,
    PG: 'TOOL',
    Description: 'TOOLS',
    January: null,
    February: null,
    March: null,
    April: null,
    May: 189.99,
    June: 199.95,
    July: null,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 119,
    PG: 'TF',
    Description: 'TRANMISSION FILTERS',
    January: 56.14,
    February: null,
    March: 28.42,
    April: 70.17,
    May: 128.96,
    June: 66.72,
    July: 69.46,
    August: 22.24,
    September: 26.75,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 120,
    PG: 'TF',
    Description: 'Transmission Filters',
    January: 56.14,
    February: null,
    March: 28.42,
    April: 70.17,
    May: 128.96,
    June: 66.72,
    July: 69.46,
    August: 22.24,
    September: 26.75,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 121,
    PG: 'TRW',
    Description: 'TRW PRODUCTS',
    January: 32.15,
    February: null,
    March: 13.62,
    April: null,
    May: null,
    June: null,
    July: null,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 122,
    PG: 'TUR',
    Description: 'TURBOS',
    January: null,
    February: null,
    March: null,
    April: null,
    May: 753.44,
    June: null,
    July: null,
    August: null,
    September: null,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 123,
    PG: 'ULT',
    Description: 'ULTRA PARTS',
    January: null,
    February: null,
    March: null,
    April: null,
    May: 96.99,
    June: null,
    July: 164,
    August: 153.99,
    September: 86.45,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 124,
    PG: 'ULT',
    Description: 'ULTRA PARTSLTD',
    January: null,
    February: null,
    March: null,
    April: null,
    May: 96.99,
    June: null,
    July: 164,
    August: 153.99,
    September: 86.45,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 125,
    PG: 'VAL',
    Description: 'VALEO',
    January: null,
    February: null,
    March: null,
    April: 100,
    May: 235,
    June: null,
    July: 290,
    August: null,
    September: 112.5,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 126,
    PG: 'VAL',
    Description: 'VALEO IMPORT ONLY',
    January: null,
    February: null,
    March: null,
    April: 100,
    May: 235,
    June: null,
    July: 290,
    August: null,
    September: 112.5,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 127,
    PG: 'WP',
    Description: 'WATER PUMP',
    January: 125,
    February: 214.99,
    March: 385.8,
    April: 353.48,
    May: 153.33,
    June: 450.54,
    July: 37.5,
    August: 81.68,
    September: 50,
    October: null,
    November: '',
    December: '',
  },
  {
    id: 128,
    PG: 'BK',
    Description: 'WHEEL BEARING KIT',
    January: 485.99,
    February: 767.35,
    March: 727.97,
    April: 632.51,
    May: 1165.3,
    June: 406.93,
    July: 458.6,
    August: 787.26,
    September: 1267.27,
    October: 820.28,
    November: '',
    December: '',
  },
  {
    id: 129,
    PG: 'WC',
    Description: 'WHEEL CYLINDER',
    January: 53.14,
    February: 78.47,
    March: 119.12,
    April: 35.04,
    May: 58.31,
    June: 47.98,
    July: 23.98,
    August: 56.07,
    September: 78.38,
    October: 15.98,
    November: '',
    December: '',
  },
  {
    id: 130,
    PG: 'WC',
    Description: 'WHEEL CYLS',
    January: 53.14,
    February: 78.47,
    March: 119.12,
    April: 35.04,
    May: 58.31,
    June: 47.98,
    July: 23.98,
    August: 56.07,
    September: 78.38,
    October: 15.98,
    November: '',
    December: '',
  },
  {
    id: 131,
    PG: 'WILL',
    Description: 'WILLENHAL',
    January: null,
    February: 15.36,
    March: 11.31,
    April: 29.18,
    May: 65.11,
    June: 146.41,
    July: 188.29,
    August: 273.29,
    September: 155.98,
    October: 95.39,
    November: '',
    December: '',
  },
  {
    id: 132,
    PG: 'WB',
    Description: 'WIPER BLADE',
    January: 146.92,
    February: 71.31,
    March: 336.14,
    April: 163.36,
    May: 151.79,
    June: 188.84,
    July: 55.57,
    August: 45.69,
    September: 206.61,
    October: 97.44,
    November: '',
    December: '',
  },
  {
    id: 133,
    PG: 'WB',
    Description: 'WIPER BLADES',
    January: 146.92,
    February: 71.31,
    March: 336.14,
    April: 163.36,
    May: 151.79,
    June: 188.84,
    July: 55.57,
    August: 45.69,
    September: 206.61,
    October: 97.44,
    November: '',
    December: '',
  },
];

const dDATA = [
  {
    id: 1,
    PG: 'ADD',
    Description: '*** Block Import ***',
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: 8.34,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 2,
    PG: 'NAPA',
    Description: '*** Block Import ***',
    Monday: 341.64,
    Tuesday: 304,
    Wednesday: 139.22,
    Thursday: 268.76,
    Friday: 383.4,
    Saturday: 152.62,
    Sunday: '',
  },
  {
    id: 3,
    PG: 'AC',
    Description: 'ACCESSORIES',
    Monday: 434.9,
    Tuesday: 249.03,
    Wednesday: 192.33,
    Thursday: 178.45,
    Friday: 614.15,
    Saturday: 4.87,
    Sunday: '',
  },
  {
    id: 4,
    PG: 'AC',
    Description: 'ACCESSORIES ETC',
    Monday: 434.9,
    Tuesday: 249.03,
    Wednesday: 192.33,
    Thursday: 178.45,
    Friday: 614.15,
    Saturday: 4.87,
    Sunday: '',
  },
  {
    id: 5,
    PG: 'AD',
    Description: 'Additives',
    Monday: 41.86,
    Tuesday: null,
    Wednesday: null,
    Thursday: 14.16,
    Friday: null,
    Saturday: 28,
    Sunday: '',
  },
  {
    id: 6,
    PG: 'AF',
    Description: 'AIR FILTER',
    Monday: 105.16,
    Tuesday: 70.82,
    Wednesday: null,
    Thursday: 15.26,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 7,
    PG: 'ANF',
    Description: 'ANTIFREEZE',
    Monday: 97.17,
    Tuesday: 19.92,
    Wednesday: 14.48,
    Thursday: 14.48,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 8,
    PG: 'BB',
    Description: 'BATTERIES',
    Monday: 59.99,
    Tuesday: null,
    Wednesday: 129.17,
    Thursday: 200.01,
    Friday: null,
    Saturday: 57,
    Sunday: '',
  },
  {
    id: 9,
    PG: 'BK',
    Description: 'Boot kits',
    Monday: 100,
    Tuesday: 157.05,
    Wednesday: 14,
    Thursday: null,
    Friday: 15,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 10,
    PG: 'BOSCH',
    Description: 'BOSCH',
    Monday: 670.34,
    Tuesday: 789.22,
    Wednesday: 628.06,
    Thursday: 555.2,
    Friday: 514.02,
    Saturday: 204.72,
    Sunday: '',
  },
  {
    id: 11,
    PG: 'RAD',
    Description: 'BOSCH RADS',
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: 75,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 12,
    PG: 'BOSCH',
    Description: 'BOSCH WIPER BLADE',
    Monday: 670.34,
    Tuesday: 789.22,
    Wednesday: 628.06,
    Thursday: 555.2,
    Friday: 514.02,
    Saturday: 204.72,
    Sunday: '',
  },
  {
    id: 13,
    PG: 'BC',
    Description: 'BRAKE CALIPERS',
    Monday: 215.42,
    Tuesday: 96.66,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 14,
    PG: 'BD',
    Description: 'BRAKE DISC/DRUMS',
    Monday: 134,
    Tuesday: 134,
    Wednesday: 166.02,
    Thursday: 216.7,
    Friday: 30.92,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 15,
    PG: 'BD',
    Description: 'BRAKE DISCS',
    Monday: 134,
    Tuesday: 134,
    Wednesday: 166.02,
    Thursday: 216.7,
    Friday: 30.92,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 16,
    PG: 'BW',
    Description: 'BRAKE FITTING KITS ETC',
    Monday: 4.99,
    Tuesday: 5,
    Wednesday: 12.5,
    Thursday: 4.99,
    Friday: 24.81,
    Saturday: 10,
    Sunday: '',
  },
  {
    id: 17,
    PG: 'BW',
    Description: 'BRAKE KITS ETC',
    Monday: 4.99,
    Tuesday: 5,
    Wednesday: 12.5,
    Thursday: 4.99,
    Friday: 24.81,
    Saturday: 10,
    Sunday: '',
  },
  {
    id: 18,
    PG: 'BP',
    Description: 'BRAKE PAD',
    Monday: 105.71,
    Tuesday: 164.17,
    Wednesday: 93.19,
    Thursday: 204.78,
    Friday: 179.47,
    Saturday: 65.44,
    Sunday: '',
  },
  {
    id: 19,
    PG: 'BP',
    Description: 'BRAKE PADS',
    Monday: 105.71,
    Tuesday: 164.17,
    Wednesday: 93.19,
    Thursday: 204.78,
    Friday: 179.47,
    Saturday: 65.44,
    Sunday: '',
  },
  {
    id: 20,
    PG: 'B',
    Description: 'BUYING PARTS',
    Monday: 606.52,
    Tuesday: 488.09,
    Wednesday: 300.52,
    Thursday: 436.06,
    Friday: 1503.18,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 21,
    PG: 'CV',
    Description: 'C.V.BOOT',
    Monday: 9.99,
    Tuesday: null,
    Wednesday: 12.5,
    Thursday: 23.97,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 22,
    PG: 'CF',
    Description: 'CABIN FILTER',
    Monday: 6.03,
    Tuesday: 6.69,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 23,
    PG: 'CF',
    Description: 'CABIN FILTERS',
    Monday: 6.03,
    Tuesday: 6.69,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 24,
    PG: 'CAM',
    Description: 'CAMBIARE',
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: 16.66,
    Friday: 46,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 25,
    PG: 'CC',
    Description: 'CAR CARE',
    Monday: 24.82,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 26,
    PG: 'CK',
    Description: 'CLUTCH KIT',
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: 110,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 27,
    PG: 'CS',
    Description: 'COIL SPRINGS',
    Monday: null,
    Tuesday: 49.48,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 28,
    PG: 'COOL',
    Description: 'COOLING',
    Monday: null,
    Tuesday: 1.67,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 29,
    PG: 'CV',
    Description: 'CV Joints',
    Monday: 9.99,
    Tuesday: null,
    Wednesday: 12.5,
    Thursday: 23.97,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 30,
    PG: 'EM',
    Description: 'ENGINE MANAGEMENT',
    Monday: 16.67,
    Tuesday: 62.5,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 31,
    PG: 'EM',
    Description: 'ENGINE MANGEMENT',
    Monday: 16.67,
    Tuesday: 62.5,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 32,
    PG: 'EX',
    Description: 'EXHASTS',
    Monday: null,
    Tuesday: 215.37,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 33,
    PG: 'EX',
    Description: 'Exhausts',
    Monday: null,
    Tuesday: 215.37,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 34,
    PG: 'FB',
    Description: 'FAN BELT',
    Monday: null,
    Tuesday: null,
    Wednesday: 31.25,
    Thursday: null,
    Friday: 16.67,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 35,
    PG: 'FB',
    Description: 'FAN BELTS',
    Monday: null,
    Tuesday: null,
    Wednesday: 31.25,
    Thursday: null,
    Friday: 16.67,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 36,
    PG: 'FF',
    Description: 'FUEL FILTER',
    Monday: 24.66,
    Tuesday: 9.6,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 37,
    PG: 'FF',
    Description: 'FUEL FILTERS',
    Monday: 24.66,
    Tuesday: 9.6,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 38,
    PG: 'GAS',
    Description: 'GASKETS',
    Monday: 16.1,
    Tuesday: 15.96,
    Wednesday: 56.91,
    Thursday: 79.8,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 39,
    PG: 'GAT',
    Description: 'GATES',
    Monday: null,
    Tuesday: 96.35,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: 11.37,
    Sunday: '',
  },
  {
    id: 40,
    PG: 'GAT',
    Description: 'GATES PRODUCTS',
    Monday: null,
    Tuesday: 96.35,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: 11.37,
    Sunday: '',
  },
  {
    id: 41,
    PG: 'GLV',
    Description: 'gloves',
    Monday: 20.32,
    Tuesday: 25.56,
    Wednesday: null,
    Thursday: 9.16,
    Friday: 20,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 42,
    PG: 'VB',
    Description: 'HANDBRAKE CABLE',
    Monday: null,
    Tuesday: 66.67,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 43,
    PG: 'INA',
    Description: 'INA PRODUCTS',
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: 79.26,
    Sunday: '',
  },
  {
    id: 44,
    PG: 'LUK',
    Description: 'LUK',
    Monday: null,
    Tuesday: 161.33,
    Wednesday: null,
    Thursday: 179.14,
    Friday: null,
    Saturday: 55,
    Sunday: '',
  },
  {
    id: 45,
    PG: 'LUK',
    Description: 'LUK CLUTCHES',
    Monday: null,
    Tuesday: 161.33,
    Wednesday: null,
    Thursday: 179.14,
    Friday: null,
    Saturday: 55,
    Sunday: '',
  },
  {
    id: 46,
    PG: 'MAH',
    Description: 'MAHLE FILTERS',
    Monday: 72.74,
    Tuesday: 81.97,
    Wednesday: 122.39,
    Thursday: 55.27,
    Friday: 104.66,
    Saturday: 4.99,
    Sunday: '',
  },
  {
    id: 47,
    PG: 'MANN',
    Description: 'MANN & HUMMEL',
    Monday: 3.43,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 48,
    PG: 'TMD',
    Description: 'MINTEX',
    Monday: null,
    Tuesday: null,
    Wednesday: 26.67,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 49,
    PG: 'MIS',
    Description: 'MISC PRODUCTS',
    Monday: 179.2,
    Tuesday: 82.41,
    Wednesday: 68.77,
    Thursday: 48.93,
    Friday: 69.01,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 50,
    PG: 'NP',
    Description: 'NUMBER PLATES',
    Monday: 12.99,
    Tuesday: 11.99,
    Wednesday: null,
    Thursday: null,
    Friday: 9.99,
    Saturday: 8.5,
    Sunday: '',
  },
  {
    id: 51,
    PG: 'FL',
    Description: 'OIL FILTER',
    Monday: 2.5,
    Tuesday: 19.64,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 52,
    PG: 'FL',
    Description: 'OIL FILTERS',
    Monday: 2.5,
    Tuesday: 19.64,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 53,
    PG: 'OIL',
    Description: 'OILS',
    Monday: 1897.26,
    Tuesday: 2954.1,
    Wednesday: 1436.74,
    Thursday: 3030.84,
    Friday: 3904.44,
    Saturday: 236.48,
    Sunday: '',
  },
  {
    id: 54,
    PG: 'RAD',
    Description: 'RADIATORS',
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: 75,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 55,
    PG: 'SP',
    Description: 'SPARK PLUG',
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: 40.28,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 56,
    PG: 'STA',
    Description: 'STARTER MOTORS',
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: 130,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 57,
    PG: 'SS',
    Description: 'STEERING & SUSPENSION',
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: 17.08,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 58,
    PG: 'SS',
    Description: 'STEERING PART',
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: 17.08,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 59,
    PG: 'SP',
    Description: 'Steering pumps',
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: 40.28,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 60,
    PG: 'SR',
    Description: 'Steering Racks',
    Monday: null,
    Tuesday: 138,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 61,
    PG: 'SR',
    Description: 'STEERING RACKS & PUMPS',
    Monday: null,
    Tuesday: 138,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 62,
    PG: 'TH',
    Description: 'THERMOSTATS',
    Monday: null,
    Tuesday: 70,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 63,
    PG: 'BK',
    Description: 'WHEEL BEARING KIT',
    Monday: 100,
    Tuesday: 157.05,
    Wednesday: 14,
    Thursday: null,
    Friday: 15,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 64,
    PG: 'WC',
    Description: 'WHEEL CYLINDER',
    Monday: null,
    Tuesday: null,
    Wednesday: 15.98,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 65,
    PG: 'WC',
    Description: 'WHEEL CYLS',
    Monday: null,
    Tuesday: null,
    Wednesday: 15.98,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
  {
    id: 66,
    PG: 'WILL',
    Description: 'WILLENHAL',
    Monday: null,
    Tuesday: null,
    Wednesday: 48.3,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: '',
  },
];

export default Pgdrift;
