import { Box } from '@mui/material';
import Header from '../../components/Header';
import axios from 'axios';
import Map from './Map';
import moment from 'moment';
import packageJson from '../../../package.json';

import { useEffect, useState } from 'react';
import '../../index.css'; // Import the index.css file from the project root

const Dashboard = ({ cid, apir, userToken, setUserToken }) => {
  const { version } = packageJson;

  const [selectedOption, setSelectedOption] = useState('Today');
  const [loadInfo, setLoadInfo] = useState([]);
  const [distanceInfo, setDistanceInfo] = useState([]);
  const [vanInfo, setVanInfo] = useState([]);

  const positions = [
    { lat: 37.7749, lng: -122.4194 },
    { lat: 34.0522, lng: -118.2437 },
    { lat: 39.9526, lng: -75.1652 },
    // Add more positions as needed
  ];

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const fetchLoadInfo = async () => {
    try {
      const response = await axios.get(
        `https://${apir}/api/dataSummary/loadInfo/${selectedOption}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setLoadInfo(response.data);
      console.info(response.data);
    } catch (error) {
      console.error('Error fetching load summary:', error);
    }
  };

  const fetchDistanceInfo = async () => {
    try {
      const response = await axios.get(
        `https://${apir}/api/dataSummary/distanceInfo/${selectedOption}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setDistanceInfo(response.data);
      console.info(response.data);
    } catch (error) {
      console.error('Error fetching distance summary:', error);
    }
  };

  const fetchVanInfo = async () => {
    try {
      const response = await axios.get(
        `https://${apir}/api/dataSummary/vans/${selectedOption}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setVanInfo(response.data);
      console.info(response.data);
    } catch (error) {
      console.error('Error fetching van summary:', error);
    }
  };

  useEffect(() => {
    // Fetch data initially
    fetchLoadInfo();
    fetchDistanceInfo();
    fetchVanInfo();

    // Set up intervals to fetch data every 30 seconds
    const loadInfoInterval = setInterval(fetchLoadInfo, 30000);
    const distanceInfoInterval = setInterval(fetchDistanceInfo, 30000);
    const vanInfoInterval = setInterval(fetchVanInfo, 30000);

    // Clean up intervals on component unmount
    return () => {
      clearInterval(loadInfoInterval);
      clearInterval(distanceInfoInterval);
      clearInterval(vanInfoInterval);
    };
  }, [apir, selectedOption]);

  return (
    <Box m="20px" style={{ flex: 1 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Vision Dashboard" />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        height="100%"
      >
        <div
          style={{
            margin: 5,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '33%' }}>
            <div className="switch-container">
              <button
                className={`switch-option ${
                  selectedOption === 'Today' ? 'selected' : ''
                }`}
                onClick={() => handleOptionChange('Today')}
              >
                TODAY
              </button>
              <button
                className={`switch-option ${
                  selectedOption === 'Week' ? 'selected' : ''
                }`}
                onClick={() => handleOptionChange('Week')}
              >
                WEEK
              </button>
              <button
                className={`switch-option ${
                  selectedOption === 'Month' ? 'selected' : ''
                }`}
                onClick={() => handleOptionChange('Month')}
              >
                MONTH
              </button>
            </div>
          </div>
          <div>version: {version}</div>
        </div>
        {/* main div 1*/}
        <div
          style={{
            width: '100%',
            height: '100%',
            flex: 1,
            flexDirection: 'row',
            display: 'flex',
          }}
        >
          {/* van info */}
          <div className={'cardCont'} style={{ flex: 1 }}>
            <div>
              <h3 className={'cardHead'}>VAN INFO</h3>
            </div>
            {/* vans in / out */}
            <div className="cellContainer">
              <div className="outerCell">
                <div className="innerCell">VANS OUT</div>
                <div className="innerCell valueCell">{vanInfo.Vans}</div>
              </div>

              <div className="outerCell">
                <div className="innerCell">EARLIEST VAN FINSH</div>
                <div className="innerCell valueCell">
                  {!vanInfo.status
                    ? moment(vanInfo.FirstFinish)
                        .add(5, 'minutes')
                        .format('HH:mm')
                    : '00:00'}
                </div>
              </div>
              <div className="outerCell">
                <div className="innerCell">LATEST VAN FINISH</div>
                <div className="innerCell valueCell">
                  {!vanInfo.status
                    ? moment(vanInfo.LastFinish)
                        .add(5, 'minutes')
                        .format('HH:mm')
                    : '00:00'}
                </div>
              </div>
            </div>
          </div>

          {/* load info */}
          <div className={'cardCont'} style={{ flex: 1 }}>
            <div>
              <h3 className={'cardHead'}>LOAD INFO</h3>
            </div>
            <div className="cellContainer">
              <div className="outerCell">
                <div className="innerCell">DROPS COMPLETE</div>
                <div className="innerCell valueCell">
                  {loadInfo.countCompletedRuns}
                </div>
              </div>
              <div className="outerCell">
                <div className="innerCell">PARTS DROPPED</div>
                <div className="innerCell valueCell">
                  {loadInfo.sumTotalParts}
                </div>
              </div>
              <div className="outerCell">
                <div className="innerCell">TOTAL VALUE</div>
                <div className="innerCell valueCell">
                  £
                  {loadInfo.sumTotalValue &&
                    loadInfo.sumTotalValue.toLocaleString('en-GB', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </div>
              </div>
              <div className="outerCell">
                <div className="innerCell">PARTS COLLECTED</div>
                <div className="innerCell valueCell">
                  {loadInfo.sumReturnPartsQty}
                </div>
              </div>
              <div className="outerCell">
                <div className="innerCell">PAYMENTS COLLECTED</div>
                <div className="innerCell valueCell">
                  £
                  {loadInfo.sumCashAmount &&
                    loadInfo.sumCashAmount.toLocaleString('en-GB', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </div>
              </div>
              <div className="outerCell">
                <div className="innerCell">PoD COLLECTED</div>
                <div className="innerCell valueCell">{loadInfo.countPod}</div>
              </div>
            </div>
          </div>
        </div>
        {/* main div 1*/}
        <div
          style={{
            width: '100%',
            height: '100%',
            flex: 1,
            flexDirection: 'row',
            display: 'flex',
          }}
        >
          {/* van info */}
          <div className={'cardCont'} style={{ flex: 1 }}>
            <div>
              <h3 className={'cardHead'}>DISTANCE INFO</h3>
            </div>
            <div className="cellContainer">
              <div className="outerCell">
                <div className="innerCell">NUMBER OF RUNS</div>
                <div className="innerCell valueCell">{distanceInfo.count}</div>
              </div>
              <div className="outerCell">
                <div className="innerCell">TOTAL MILES</div>
                <div className="innerCell valueCell">
                  {distanceInfo.totalMiles}
                </div>
              </div>
              <div className="outerCell">
                <div className="innerCell">AVERAGE MILES P/RUN</div>
                <div className="innerCell valueCell">
                  {isNaN(distanceInfo.averageMiles)
                    ? 'N/A'
                    : distanceInfo.averageMiles}
                </div>
              </div>
              <div className="outerCell">
                <div className="innerCell">SHORTEST RUN</div>
                <div className="innerCell valueCell">
                  {isFinite(parseFloat(distanceInfo.shortestRun))
                    ? distanceInfo.shortestRun
                    : 'N/A'}
                </div>
              </div>
              <div className="outerCell">
                <div className="innerCell">LONGEST RUN</div>
                <div className="innerCell valueCell">
                  {isFinite(parseFloat(distanceInfo.longestRun))
                    ? distanceInfo.longestRun
                    : 'N/A'}
                </div>
              </div>
            </div>
          </div>

          {/* load info */}
          <div className={'cardCont'} style={{ flex: 1 }}>
            <div>
              <h3 className={'cardHead'}>MAP</h3>
              <div>{/* <Map positions={positions} /> */}</div>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Dashboard;
