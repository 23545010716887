import moment from 'moment'

import mechanic from '../../assets/mechanic.png';
import home from '../../assets/home.png';

const GarageComponent = ({ acct, stus, isHighlightingDrop, isActive, setHighlightDrop, ind, name, eta }) => {
  let opacity = 1;
  let zind = 1;

  if (isHighlightingDrop) {
    if (isActive) {
      zind = 69
    } else {
      opacity = 0.2
      zind = -1
    }
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        zIndex: zind,
        opacity: opacity,
        transition: 'all 250ms ease-in-out',
      }}
      onMouseEnter={() => setHighlightDrop(ind)}
      onMouseLeave={() => setHighlightDrop(false)}
    >
      <div
        style={{
          width: 25,
          height: 25,
          padding: 20,
          //backgroundColor: text === highlightDrop ? 'yellow' : '',
          outline: 'none',
          //border: text === highlightDrop ? '2px solid green' : '',
          borderRadius: '15px',
          //borderColor: text === highlightDrop ? 'white' : '',
          //boxShadow: text === highlightDrop ? '0 0 30px white' : '',
          backgroundImage: acct === '|none|' ? `url(${home})` : `url(${mechanic})`,
          backgroundSize: 'contain',
          backgroundColor: acct === '|none|' && 'white'
        }}
      >

      </div>

      <div>
        <h2
          style={{
            backgroundColor: 'white',
            color: 'black',
            width: 100,
            textAlign: 'center',
            padding: 2,
            borderRadius: 15,
            fontSize: 10,
            border: '2px solid black',
            margin: '2px',
          }}
        >
          {name}
        </h2>
        <h2
          style={{
            backgroundColor: stus === "Complete" ? 'green' : stus === "Void" ? 'darkred' : 'darkorange',
            color: stus === "Pending" ? 'black' : 'white',
            width: 100,
            textAlign: 'center',
            padding: 2,
            borderRadius: 15,
            fontSize: 10,
            border: '2px solid black',
            margin: '2px',
            fontWeight: 900,
          }}
        >
          {stus === "Complete" ? 'COMPLETED' : stus === "Void" ? 'VOID' : 'ETA:' + moment(eta).format('HH:mm')}
        </h2>
      </div>
    </div>
  );
};

export default GarageComponent