import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { tokens } from '../../theme';
import axios from 'axios';
import logo from '../../logo.png';
import { useState } from 'react';
import img from '../../assets/cardb.png';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://automm.co.uk/">
        AutoMM
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInSide({
  isLogged,
  setIsLogged,
  userToken,
  setUserToken,
  setCompanyName,
  brandingBlob,
  setBrandingBlob,
  apir,
  setApir,
  cid,
  setCid,
  userObj,
  setUserObj,
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const urlBase = 'https://vision-web-api-test.azurewebsites.net/';
  const authURL = 'api/authenticate';
  const userURL = 'api/user';
  const enqsURL = 'api/connectplus/factor/enquiries/';
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [loginMsg, setLoginMsg] = useState('');

  let paramData = {};

  const getApir = async (cid) => {
    //alert(cid);
    try {
      const { data } = await axios({
        method: 'GET',
        url: 'https://autommapir.connectplus.parts/api/getCompany/' + cid,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (window.location.href !== `https://${data[0].CompanyDomain}/`) {
        if (window.location.href !== 'http://localhost:4200/') {
          setUserToken(null);
          setIsLogged(false);
          //alert(window.location.href);
          throw new Error("This isn't your server");
        }
      } else {
        setApir(data[0].CompanyDomain);
        setIsLogged(true);
      }
      if (window.location.href === 'http://localhost:4200/') {
        setApir(data[0].CompanyDomain);
        setIsLogged(true);
      }
      //alert(JSON.stringify(data));
    } catch (e) {
      alert(
        'There was an error connecting to the APIR server. Please restart the app or contact AutoMM support on 0121 285 9454 - ' +
          e
      );
      console.error(e);
      throw e;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await getToken();

    // const data = new FormData(event.currentTarget);
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });
  };

  const getToken = async () => {
    const res = await axios({
      method: 'POST',
      url: urlBase + authURL,
      data: { username: user, password: pwd },
      headers: {
        'Content-Type': 'application/json',
      },
    }).catch((error) => {
      setLoginMsg("Couldn't login. Login not recognised");
    });
    if (res.data.token) {
      await getCompany(res.data.token);
      console.info(res.data.token);
      setUserToken(res.data.token);
    } else {
      setLoginMsg("Couldn't login. Login not recognised");
    }
  };

  const getCompany = async (token) => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: urlBase + '/api/user',
        headers: {
          'Content-Type': 'application/json',
          token: token,
        },
      });
      localStorage.setItem('company', JSON.stringify(data));
      setUserObj(data);
      setCompanyName(data.CompanyName);
      console.log(JSON.stringify(data.CompanyID));
      setCid(data.CompanyID);
      //alert(data.CompanyID);
      getApir(data.CompanyID);
      getImageFromBlobId(data.CompanyBrandingBlobStorageID, token).then(
        (data) => {
          setBrandingBlob(data);
        }
      );
    } catch (e) {
      alert(
        'There was an error getting company info. Please restart the app or contact AutoMM support on 0121 285 9454'
      );
      console.error(e);
      throw e;
    }
  };

  const getImageFromBlobId = async (blobId, token) => {
    console.log(`${urlBase}/api/image/${blobId}`);
    console.log(token);
    const { res } = await axios({
      method: 'GET',
      url: `${urlBase}/api/image/${blobId}`,
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    });
    //alert(JSON.stringify(res));

    const blob = await res.blob();
    const base64 = await blobToBase64(blob);
    return base64;
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${img})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'left',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100vh',
              justifyContent: 'center',
            }}
          >
            <img style={{ width: 450, padding: 50 }} src={logo} alt="Logo" />
            <Avatar sx={{ m: 1 }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                value={user}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => setUser(e.target.value)}
                autoFocus
              />
              <TextField
                value={pwd}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                autoComplete="current-password"
              />
              <h2>{loginMsg}</h2>
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                // onClick={this.handleClick}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
