import { CardContent, Typography, LinearProgress, Box } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import styles from '../../src/scenes/activeruns/style.module.scss';

const CardComponent = ({ run, imSelected }) => {
  const { driverName, startTime, endTime, drops, documents, parts, value } =
    'here';
  const [tg, setTg] = useState(0);
  const [tp, setTp] = useState(0);
  const [td, setTd] = useState(0);
  const [tdo, setTdo] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [completedPerc, setCompletedPerc] = useState(0);
  const [pending, setPending] = useState(0);
  const [voided, setVoid] = useState(0);
  const [finalEta, setFinalEta] = useState(0);
  const [progColor, setProgColor] = useState('error');
  //console.table(JSON.stringify(run));

  function calculateSummary(activeRuns) {
    setCompleted(0);
    setPending(0);
    setVoid(0); // Initializing the count for Void status
    //console.info(activeRuns);
    let totalGoods = 0;
    let totalParts = 0;
    let documentCount = 0;
    let dropCount = activeRuns.length;

    activeRuns.forEach((run) => {
      //console.log(run.Status);
      setCompleted((prevCompleted) =>
        run.Status === 'Complete' ? prevCompleted + 1 : prevCompleted
      );
      setPending((prevPending) =>
        run.Status === 'Pending' ? prevPending + 1 : prevPending
      );
      setVoid((prevVoid) => (run.Status === 'Void' ? prevVoid + 1 : prevVoid)); // Updating the count for Void status
      totalGoods += run.totalGoods || 0;
      totalParts += run.totalParts || 0;
      documentCount += run.Document ? run.Document.length : 0;
    });

    setTg(totalGoods);
    setTp(totalParts);
    setTdo(documentCount);
    setTd(dropCount);

    return {
      totalGoods,
      totalParts,
      documentCount,
    };
  }

  //alert(JSON.stringify(imSelected));
  useEffect(() => {
    calculateSummary(run.ActiveRuns);
    console.info(run.ActiveRuns);
    setFinalEta(run.ActiveRuns.slice(-1)[0].Eta);
    //alert(JSON.stringify(run.ActiveRuns.slice(-1)[0].Eta));
  }, [run]);

  useEffect(() => {
    const totalDrops = completed + voided + (pending - 1);
    let completedPercentage = (completed / totalDrops) * 100;
    setCompletedPerc(completedPercentage);
    let color = 'error';
    if (completedPercentage >= 75) {
      color = 'success'; // Green for high progress
    } else if (completedPercentage >= 50) {
      color = 'info'; // Blue for medium progress
    } else if (completedPercentage >= 25) {
      color = 'warning'; // Orange for moderate progress
    } else {
      color = 'error'; // Red for low progress
    }
    setProgColor(color);
  }, [completed, voided, pending]);

  //console.info('RUNNING', run);

  return (
    <Fragment>
      <div
        style={{
          cursor: 'pointer',
          padding: 10,
          border: '1px solid white',
          borderRadius: 15,
          margin: 5,
          marginBottom: 20,
          backgroundColor: imSelected === run.Driver ? 'darkgreen' : '',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h3
            style={{
              cursor: 'hand',
              marginTop: 0,
              marginBottom: 10,
              background: 'linear-gradient(to bottom, #f8d038 0%,#f5ca2e 100%)',
              border: '2px solid black',
              borderRadius: '8px',
              padding: 5,
              fontWeight: 'bold',
              fontSize: '2em',
              fontFamily: 'helvetica, ariel, sans-serif',
              textAlign: 'center',
              letterSpacing: '1px',
              color: 'black',
              maxWidth: '200px',
            }}
          >
            {run.User}
          </h3>
        </div>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {run.Driver}
        </Typography> */}

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',

              alignItems: 'center',
            }}
          >
            START:
            <h2 style={{ margin: 0 }} color="text.secondary">
              {moment(run.ActiveRuns[0].Date).format('HH:mm')}
            </h2>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            EST. END:
            <h2 style={{ margin: 0 }} color="text.secondary">
              {moment(finalEta).format('HH:mm')}
            </h2>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            margin: 10,
            justifyContent: 'space-between',
          }}
        >
          <div style={{ flex: 1, textAlign: 'center' }}>
            <h5
              style={{ margin: 0, textAlign: 'center' }}
              color="text.secondary"
            >
              DROPS
            </h5>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <h5 style={{ margin: 0 }} color="text.secondary">
              DOCS
            </h5>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <h5 style={{ margin: 0 }} color="text.secondary">
              PARTS
            </h5>
          </div>
          <div style={{ flex: 1.5, textAlign: 'center' }}>
            <h5 style={{ margin: 0 }} color="text.secondary">
              VALUE
            </h5>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: 10,
          }}
        >
          <div style={{ flex: 1, textAlign: 'center' }}>
            <h3
              style={{ margin: 0, textAlign: 'center' }}
              color="text.secondary"
            >
              {td - 1} {/*take away return journey*/}
            </h3>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <h3 style={{ margin: 0 }} color="text.secondary">
              {tdo}
            </h3>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <h3 style={{ margin: 0 }} color="text.secondary">
              {tp}
            </h3>
          </div>
          <div style={{ flex: 1.5, textAlign: 'center' }}>
            <h3 style={{ margin: 0 }} color="text.secondary">
              £{tg.toFixed(2)}
            </h3>
          </div>
        </div>
        <Box sx={{ width: '100%', marginTop: 2 }}>
          <Typography variant="body1" gutterBottom>
            Progress
          </Typography>
          <LinearProgress
            variant="determinate"
            value={completedPerc}
            color={progColor}
          />
          <h4
            style={{
              width: '100%',
              textAlign: 'center',
              marginTop: 5,
              marginBottom: 0,
            }}
          >
            {completed} Completed | {voided} Voided | {pending - 1} Pending
          </h4>
        </Box>
        {/* <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div style={{ textAlign: 'center', flex: 1 }}>COMPLETED</div>
          <div style={{ textAlign: 'center', flex: 1 }}>VOID</div>
          <div style={{ textAlign: 'center', flex: 1 }}>PENDING</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div style={{ textAlign: 'center', flex: 1 }}>{completed}</div>
          <div style={{ textAlign: 'center', flex: 1 }}>{voided}</div>
          <div style={{ textAlign: 'center', flex: 1 }}> {pending - 1}</div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default CardComponent;
