import { Box } from '@mui/material';
import Header from '../../components/Header';

const Dashboard = ({ cid, apir }) => {
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Deleted Runs" subtitle="Vision Dashboard" />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div>{cid}</div>
        <div>{apir}</div>
      </Box>
    </Box>
  );
};

export default Dashboard;
