import {
  Box,
  useTheme,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import styles from './style.module.scss';
import CardComponent from '../../components/CardComponent';
import { fitBounds } from 'google-map-react';
import moment from 'moment';
import GarageComponent from './GarageComponent';
import DriverComponent from './DriverComponent';
import { DataArray } from '@mui/icons-material';
import imgCash from '../../assets/img/cash.png';
import imgPod from '../../assets/img/sig.png';
import imgCam from '../../assets/img/cam.png';
import imgReturn from '../../assets/img/exchange.png';
import Receipts from './Receipts';

const ActiveRuns = ({ apir, userToken }) => {
  const theme = useTheme();
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(false);
  const [highlightDrop, setHighlightDrop] = useState(false);
  const [imSelected, setImSelected] = useState('');
  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const [dropId, setDropId] = useState(null);
  const [receipts, setReceipts] = useState(null);
  const [countdown, setCountdown] = useState(30); // Initial countdown value
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branches, setBranches] = useState([]);
  const [mapHeight, setMapHeight] = useState('50vh');

  console.log('RENDER', selectedBranch);

  useEffect(() => {
    fetchActiveRuns();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        fetchActiveRuns();
        setCountdown(30);
      }
    }, 1000);

    // Clean up timeout on component unmount
    return () => {
      clearTimeout(timeout);
    };
  }, [countdown]);

  useEffect(() => {
    console.info('the branch changed');
    fetchActiveRuns();
  }, [selectedBranch]);

  useEffect(() => {
    if (branches.length < 1) {
      //alert(branches);
      getBranches();
    }
    fetchActiveRuns();
  }, [apir]);

  const handleChange = (event) => {
    setSelectedBranch(event.target.value);
  };

  const getReceipts = async (dropId) => {
    try {
      const { data } = await axios.get(
        `https://${apir}/api/allDropInfo/${dropId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (data) {
        // Optionally, check for other attributes or structure if needed
        setReceipts(data);
        console.info('receipts', data);
      } else {
        console.error('Received unexpected data format from the API.');
      }
    } catch (error) {
      console.error('Error fetching receipts:', error);
      // Optionally, you can handle the error further, maybe setting some state to display a message to the user
    }
  };

  const fetchActiveRuns = async () => {
    console.info('branch :', selectedBranch);
    if (!selectedBranch) return;

    try {
      setLoading(true); // Set loading to true
      console.log(`https://${apir}/api/factor/getAllActive/${selectedBranch}`);
      const response = await axios.get(
        `https://${apir}/api/factor/getAllActive/${selectedBranch}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = response.data;
      if (data && data.length > 0) {
        console.info('found runs');
        const newDrivers = Array.isArray(data) ? data : [data];
        setDrivers(newDrivers);

        // After setting the drivers, check if selectedDriver still exists
        if (selectedDriver !== false) {
          const driverIdOfSelected = drivers[selectedDriver].Driver;
          const updatedDriverIndex = newDrivers.findIndex(
            (driver) => driver.Driver === driverIdOfSelected
          );

          if (updatedDriverIndex === -1) {
            // If the previously selected driver no longer exists in the updated list
            setSelectedDriver(false);
            setImSelected('');
          } else if (updatedDriverIndex !== selectedDriver) {
            // If the driver exists but at a different index in the updated list
            setSelectedDriver(updatedDriverIndex);
          }
        }
      } else {
        console.info('no runs');
        setSelectedDriver(false); // Reset selected driver if there are no active runs
        setImSelected('');
        setDrivers([]); // Set drivers to an empty array when there are no active runs
      }
    } catch (error) {
      console.error('ERROR!!!!!!!!!!!!', error);
    } finally {
      setLoading(false); // Set loading back to false
    }
  };

  const driverLocs = drivers.map((item, ind) => ({
    type: 'driver',
    name: item.User ? item.User : 'Unknown',
    lat: item.Location[0].lat,
    lon: item.Location[0].lon,
    id: item.Driver,
  }));

  const garageLocs =
    selectedDriver !== false &&
    drivers.length > 0 &&
    drivers[selectedDriver] &&
    drivers[selectedDriver].ActiveRuns
      ? drivers[selectedDriver].ActiveRuns.map((item, ind) => {
          console.info('garage locs: ', item);
          return {
            stus: item.Status,
            type: 'garage',
            name: item.Name,
            lat: item.Loc && item.Loc[0] ? item.Loc[0].lat : null,
            lon: item.Loc && item.Loc[0] ? item.Loc[0].lon : null,
            eta: item.Eta,
            id: item._id,
            acct: item.Acct,
          };
        })
      : [];

  const locsToDisplay =
    selectedDriver !== false
      ? [driverLocs[selectedDriver], ...garageLocs]
      : driverLocs;

  //console.log(drivers);

  const bounds = calculateBounds(locsToDisplay);
  const size = { width: 640, height: 380 };
  const mapFit = fitBounds(bounds, size);

  function getRemainingTime(eta) {
    const etaMoment = moment(eta);
    const currentTime = moment();
    const duration = moment.duration(etaMoment.diff(currentTime));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    return `${hours > 0 ? hours + 'h' : ''}${minutes > 0 ? minutes + 'm' : ''}`;
  }

  const getBranches = async () => {
    try {
      const response = await axios.get(`https://${apir}/api/factor/branches`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      //console.info(response.data)
      if (response.data) {
        console.info('got the branches');
        setBranches(response.data);
        setSelectedBranch(response.data[0].value); // add this line
      } else {
      }
    } catch (error) {
      // Handle error
      //alert('Branch error: ' + error);
      throw error;
    }
  };

  return (
    <Box m="20px">
      {/* apir: {apir} */}
      {/* selected driver: {selectedDriver} */}
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>Loading...</div>
      ) : (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          Next update for {selectedBranch} in {countdown} seconds
        </div>
      )}
      <div className={styles.grid}>
        <div className={styles.drivers}>
          {/* <Card>drivers</Card> */}
          <div style={{ height: '100%' }}>
            <FormControl style={{ width: '100%', marginTop: 10 }}>
              <InputLabel shrink id="demo-simple-select-label">
                Location
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedBranch}
                label="Branch"
                onChange={handleChange}
              >
                {branches.map((branches, index) => (
                  <MenuItem key={index} value={branches.value}>
                    {branches.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div
              style={{
                textAlign: 'center',
                borderRadius: 15,
                backgroundColor: 'dodgerblue',
                marginLeft: '10px',
                marginRight: '10px',
              }}
              onClick={() => {
                fetchActiveRuns();
                setSelectedDriver(false);
                setImSelected('');
                setShowMap(true);
              }}
            >
              <h3>SHOW ALL DRIVERS</h3>
            </div>
            {/* <div>
              <label>
                <input
                  type="radio"
                  value="ETA"
                  checked={selectedOption === 'ETA'}
                  onChange={handleOptionChange}
                />
                ETA
              </label>
              <label>
                <input
                  type="radio"
                  value="COUNTDOWN"
                  checked={selectedOption === 'COUNTDOWN'}
                  onChange={handleOptionChange}
                />
                COUNTDOWN
              </label>
            </div> */}
            {/* LIST DRIVERS */}
            {drivers &&
              drivers.map((run, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setSelectedDriver(index);
                    setImSelected(run.Driver);
                  }}
                >
                  <CardComponent
                    key={index}
                    imSelected={imSelected}
                    variant="outlined"
                    run={run}
                  ></CardComponent>
                </div>
              ))}
          </div>
        </div>

        <div className={styles.map}>
          <Card>
            {/* MAP */}
            {/* <div>{imSelected}</div> */}
            <div
              style={{
                height: selectedDriver !== false ? '50vh' : '90vh',
                width: '100%',
              }}
            >
              {showMap ? (
                <GoogleMapReact
                  center={mapFit.center}
                  zoom={mapFit.zoom}
                  bootstrapURLKeys={{
                    key: 'AIzaSyD0iJOva-43FLdpuIG7N1GSOJ4fixfSmj0',
                  }}
                >
                  {locsToDisplay.reverse().map((loc, ind) => {
                    console.info('locs to display', locsToDisplay);
                    if (!loc) {
                      return;
                    } else if (loc.type === 'driver') {
                      return (
                        <DriverComponent
                          key={ind}
                          lat={loc.lat}
                          lng={loc.lon}
                          name={loc.name}
                          apir={apir}
                        />
                      );
                    } else {
                      return (
                        <GarageComponent
                          key={ind}
                          isHighlightingDrop={highlightDrop !== false}
                          isActive={highlightDrop === loc.id}
                          setHighlightDrop={setHighlightDrop}
                          ind={loc.id}
                          lat={loc.lat}
                          lng={loc.lon}
                          name={loc.name}
                          eta={loc.eta}
                          stus={loc.stus}
                          acct={loc.acct}
                        />
                      );
                    }
                  })}
                </GoogleMapReact>
              ) : (
                <Receipts
                  userToken={userToken}
                  receipts={receipts}
                  apir={apir}
                  setShowMap={setShowMap}
                />
              )}
            </div>
          </Card>
        </div>

        <div className={styles.detailCont}>
          {selectedDriver !== false &&
            drivers[selectedDriver].ActiveRuns.map((run, index) => {
              return (
                <div
                  key={index}
                  className={styles.detailCard}
                  onMouseEnter={() => {
                    setHighlightDrop(run._id);
                  }}
                  onMouseLeave={() => {
                    setHighlightDrop(false);
                  }}
                >
                  <div>
                    <h2
                      className={[styles.noMargin, styles.acctHeader].join(' ')}
                    >
                      {run.Acct} - {run.Name}
                    </h2>
                    <p className={styles.noMargin}>
                      {run.Address}, {run.Pcode}
                    </p>
                    <h2
                      style={{
                        backgroundColor: 'white',
                        color: 'black',
                        textAlign: 'center',
                        borderRadius: 10,
                        margin: 3,
                      }}
                      className={styles.noMargin}
                    >
                      {run.Status == 'Complete' ? (
                        'COMPLETE'
                      ) : run.Status == 'Void' ? (
                        'VOID'
                      ) : (
                        <>
                          ETA: {moment(run.Eta).format('HH:mm')} -{' '}
                          {getRemainingTime(run.Eta)}
                        </>
                      )}
                    </h2>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                        marginTop: 10,
                      }}
                    >
                      <h3 className={styles.noMargin}>
                        {run.totalParts} Parts
                      </h3>
                      <h3 className={styles.noMargin}>
                        £{run.totalGoods.toFixed(2)}
                      </h3>
                      <div className={styles.bnumber}>
                        <h3 className={styles.noMargin}>{index + 1}</h3>
                      </div>
                    </div>
                  </div>
                  <hr style={{ width: '100%' }}></hr>
                  <div style={{ flex: 1, overflow: 'scroll' }}>
                    {run.parts &&
                      Object.keys(run.parts).map((documentKey) => (
                        <div key={documentKey} style={{ marginBottom: 10 }}>
                          <h4 style={{ margin: 0 }}>DOCUMENT: {documentKey}</h4>
                          {run.parts[documentKey].map((part, index) => (
                            <div key={index}>
                              <p style={{ margin: 0 }}>
                                {part.Part} x {part.Qty}
                              </p>
                            </div>
                          ))}
                        </div>
                      ))}
                    {!run.parts ||
                      (Object.keys(run.parts).length === 0 && (
                        <p>No parts found</p>
                      ))}
                  </div>
                  {run.Status === 'Complete' ||
                    (run.Status === 'Void' && (
                      <hr style={{ width: '100%' }}></hr>
                    ))}
                  {run.Status === 'Complete' || run.Status === 'Void' ? (
                    <div
                      onClick={() => {
                        setDropId(run.DropId);
                        getReceipts(run.DropId);
                        setShowMap(!showMap);
                      }}
                      style={{
                        marginBottom: '0.5em',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: 5,
                      }}
                    >
                      <div
                        class="circle"
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                          backgroundColor: run.isPod ? 'green' : 'darkgrey',
                          border: '2px solid white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            borderRadius: '50%',
                          }}
                          src={imgPod}
                          alt="Pod"
                        />
                      </div>
                      <div
                        class="circle"
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                          backgroundColor: run.isCash ? 'green' : 'darkgrey',
                          border: '2px solid white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            borderRadius: '50%',
                          }}
                          src={imgCash}
                          alt="Cash"
                        />
                      </div>
                      <div
                        class="circle"
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                          backgroundColor: run.isReturnParts
                            ? 'green'
                            : 'darkgrey',
                          border: '2px solid white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            borderRadius: '50%',
                          }}
                          src={imgReturn}
                          alt="return"
                        />
                      </div>
                      <div
                        class="circle"
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                          backgroundColor: run.isPhoto ? 'green' : 'darkgrey',
                          border: '2px solid white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            borderRadius: '50%',
                          }}
                          src={imgCam}
                          alt="return"
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div
                    className={
                      run.Status === 'Pending'
                        ? styles.pending
                        : run.Status === 'Void'
                        ? styles.void
                        : styles.completed
                    }
                  >
                    <h3 className={styles.noMargin}>{run.Status}</h3>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Box>
  );
};

const calculateBounds = (data) => {
  if (data[0]) {
    //console.info('data:', data[0]);
  }
  //default to show UK
  if (!data || data.length === 0 || !data[0])
    return {
      nw: { lat: 58.723, lng: -10.464 },
      se: { lat: 51.166, lng: 1.774 },
    };

  let minLat = Infinity;
  let minLng = Infinity;
  let maxLat = -Infinity;
  let maxLng = -Infinity;
  const padding = 0.045; // Adjust the padding value as needed

  data.forEach((run) => {
    minLat = Math.min(minLat, run.lat);
    minLng = Math.min(minLng, run.lon);
    maxLat = Math.max(maxLat, run.lat);
    maxLng = Math.max(maxLng, run.lon);
  });

  // Add padding to the bounds
  const latPadding = Math.abs(maxLat - minLat) * padding || 0.5;
  const lngPadding = Math.abs(maxLng - minLng) * padding || 0.5;
  const paddedMinLat = minLat - latPadding;
  const paddedMinLng = minLng - lngPadding;
  const paddedMaxLat = maxLat + latPadding;
  const paddedMaxLng = maxLng + lngPadding;

  return {
    nw: { lat: paddedMaxLat, lng: paddedMinLng },
    se: { lat: paddedMinLat, lng: paddedMaxLng },
  };
};

export default ActiveRuns;
