import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  XGrid,
} from '@mui/x-data-grid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../index.css'; // Import the index.css file from the project root

import moment from 'moment';

const Locations = ({ apir, userToken }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [jsonData, setJsonData] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [runDetails, setRunDetails] = useState([]);
  const [mapsLoaded, setMapsLoaded] = useState(false);
  const [zoomEnabled, setZoomEnabled] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [userOptions, setUserOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const markersRef = useRef([]);
  const datePickerRef = useRef(null);

  useEffect(() => {
    if (datePickerRef.current) {
      const inputElement = datePickerRef.current.input;
      if (inputElement) {
        inputElement.setAttribute('autocomplete', 'off');
      }
    }
  }, []);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!window.google) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBSmef91X0hKYCpmiHMJg9cEtqQXEJvESk&libraries=visualization`;
        script.async = true;
        script.onload = () => {
          setMapsLoaded(true);
          initializeMap();
        };
        document.body.appendChild(script);
      } else {
        setMapsLoaded(true);
        initializeMap();
      }
    };

    const initializeMap = () => {
      mapRef.current = new window.google.maps.Map(mapContainerRef.current, {
        center: { lat: 52.5835511, lng: -2.1054897 },
        zoom: 8,
      });
      markersRef.current = []; // Initialize markersRef
    };

    loadGoogleMapsScript();
  }, []);

  useEffect(() => {
    if (!apir) return;

    fetchData();
    fetchUserOptions();
  }, [apir, selectedUser, selectedDate]);

  const fetchRunData = (runId) => {
    const apiUrl = `https://${apir}/api/locations/runcheck/${runId}`;

    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((response) => {
        const data = response.data.map((item) => ({
          Acct: item.Acct,
          Name: item.Name,
          Address: item.Address,
          Pcode: item.Pcode,
          collection: item.collection,
          id: item._id, // Add a unique identifier property
        }));
        setRunDetails(data);
      })
      .catch((error) => {
        console.error('Failed to fetch run data:', error);
      });
  };

  useEffect(() => {
    if (mapsLoaded && window.google && mapRef.current) {
      markersRef.current.forEach((marker) => marker.setMap(null));
      markersRef.current = [];

      jsonData.forEach((item) => {
        const { lat, lon, id } = item.Location[0];
        const marker = new window.google.maps.Marker({
          position: { lat, lng: lon },
          map: mapRef.current,
          label: item.markerNumber,
          rowId: id,
        });
        markersRef.current.push(marker);
      });

      // Reset map zoom
      const bounds = new window.google.maps.LatLngBounds();
      markersRef.current.forEach((marker) => {
        bounds.extend(marker.getPosition());
      });

      if (markersRef.current.length > 0) {
        mapRef.current.fitBounds(bounds);
      } else {
        // Fallback to the initial map center and zoom
        mapRef.current.setCenter({ lat: 52.5835511, lng: -2.1054897 });
        mapRef.current.setZoom(8);
      }
    }
  }, [mapsLoaded, jsonData]);

  const fetchData = () => {
    let formattedDatetime = '';

    if (selectedDate) {
      const offset = selectedDate.getTimezoneOffset() * 60 * 1000;
      const adjustedDatetime = new Date(selectedDate.getTime() - offset);
      formattedDatetime = adjustedDatetime.toISOString();
    }

    const apiUrl = `https://${apir}/api/locations/getlocs/${selectedUser}/${formattedDatetime}`;

    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((response) => {
        const data = response.data.map((item, index) => {
          const formattedTime = moment(item.DateTime).format('HH:mm:ss'); // Format DateTime to display only the time
          return {
            ...item,
            id: index + 1,
            markerNumber: (index + 1).toString(),
            DateTime: formattedTime, // Update DateTime property with formatted time
          };
        });
        setJsonData(data);
        setRunDetails([]);
      })
      .catch((error) => {
        console.error('Failed to fetch JSON data:', error);
      });
  };

  const fetchUserOptions = () => {
    axios
      .get(`https://${apir}/api/location/users`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((response) => {
        setUserOptions(response.data);
      })
      .catch((error) => {
        console.error('Failed to fetch user options:', error);
      });
  };

  const handleRowHovered = (event) => {
    const hoveredRowIndex = parseInt(
      event.currentTarget.getAttribute('data-rowindex'),
      10
    );

    if (selectedRow) {
      return;
    }

    markersRef.current.forEach((marker, index) => {
      if (index === hoveredRowIndex) {
        marker.setMap(mapRef.current);
        const position = marker.getPosition();
        console.log('Hovered Marker Position:', position.lat(), position.lng());

        mapRef.current.panTo(position);

        if (zoomEnabled) {
          mapRef.current.setZoom(mapRef.current.getZoom() + 4);
        }
      } else {
        marker.setMap(null);
      }
    });
  };

  const handleRowLeaved = () => {
    if (selectedRow) {
      return;
    }

    markersRef.current.forEach((marker) => {
      marker.setMap(mapRef.current);
    });

    const bounds = new window.google.maps.LatLngBounds();

    markersRef.current.forEach((marker) => {
      bounds.extend(marker.getPosition());
    });

    if (markersRef.current.length > 0) {
      mapRef.current.fitBounds(bounds);
    } else {
      // Fallback to the initial map center and zoom
      mapRef.current.setCenter({ lat: 52.5835511, lng: -2.1054897 });
      mapRef.current.setZoom(8);
    }
  };

  const handleRowClicked = (event) => {
    setSelectedRow(!selectedRow);
  };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleRefresh = () => {
    fetchData();
  };

  const handleOnCellClick = (params) => {
    fetchRunData(params.value); // Access cell value
    // Or
  };

  return (
    <div style={{ margin: 30 }}>
      <div
        ref={mapContainerRef}
        style={{ width: '100%', height: '500px' }}
      ></div>
      <div style={{ height: 400, marginTop: 20 }}>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 10,
            alignItems: 'center',
          }}
        >
          <div>
            <label style={{ marginRight: 5 }} htmlFor="zoomSwitch">
              Zoom Enabled:
            </label>
            <input
              type="checkbox"
              id="zoomSwitch"
              checked={zoomEnabled}
              onChange={(event) => setZoomEnabled(event.target.checked)}
            />
          </div>
          <div>
            <label style={{ marginRight: 15 }} htmlFor="userSelect">
              Select User:
            </label>
            <select
              id="userSelect"
              value={selectedUser}
              onChange={handleUserChange}
              style={{
                padding: '8px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                fontSize: '14px',
                width: '200px',
                textAlign: 'center',
              }}
            >
              <option value="">All Users</option>
              {userOptions.map((user) => (
                <option key={user.value} value={user.value}>
                  {user.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label style={{ marginRight: 15 }} htmlFor="datePicker">
              Select Date:
            </label>
            <DatePicker
              id="datePicker"
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="yyyy-MM-dd"
              placeholderText="Select date"
              ref={datePickerRef}
              calendarAriaLabel="Toggle calendar"
              clearAriaLabel="Clear value"
              dayAriaLabel="Day"
              monthAriaLabel="Month"
              nativeInputAriaLabel="Date"
              yearAriaLabel="Year"
              style={{
                padding: '8px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                fontSize: '14px',
                width: '200px',
              }}
            />
          </div>
          <div>
            <button
              style={{
                padding: '8px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                fontSize: '14px',
                width: '75px',
              }}
              onClick={handleRefresh}
            >
              Refresh
            </button>
          </div>
        </div>
        <div
          id="details"
          style={{
            marginBottom: 20,
            display: runDetails.length > 0 ? 'block' : 'none',
          }}
        >
          <DataGrid
            rows={runDetails}
            autoHeight={true}
            hideFooter={true}
            columns={[
              { field: 'Acct', headerName: 'Acct', flex: 1 },
              { field: 'Name', headerName: 'Name', flex: 2 },
              { field: 'Address', headerName: 'Address', flex: 2 },
              { field: 'Pcode', headerName: 'PCode', flex: 1 },
              { field: 'collection', headerName: 'Status', flex: 1 },
            ]}
          />
        </div>
        <DataGrid
          rows={jsonData}
          getRowId={(row) => row.id}
          onCellClick={handleOnCellClick}
          classes={{
            cell: 'custom-cell',
            headerCell: 'custom-header-cell',
          }}
          componentsProps={{
            row: {
              onMouseEnter: handleRowHovered,
              onMouseLeave: handleRowLeaved,
              onClickCapture: handleRowClicked,
            },
          }}
          columns={[
            { field: 'markerNumber', headerName: 'Marker', flex: 1 },
            { field: 'DateTime', headerName: 'DateTime', flex: 2 },
            { field: 'User', headerName: 'User', flex: 2 },
            // { field: 'Location', headerName: 'Location', flex: 1 },
            // { field: 'RunId', headerName: 'RunId', flex: 1 },
          ]}
          pageSize={20}
        />
      </div>
    </div>
  );
};

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
};

export default Locations;
