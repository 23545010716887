import { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Topbar from './scenes/global/Topbar';
import Sidebar from './scenes/global/Sidebar';
import Dashboard from './scenes/dashboard';
import Pod from './scenes/pod';
import Pgdrift from './scenes/pgdrift';
import Cdrift from './scenes/cdrift';
import Cspent from './scenes/cspent';
import Cash from './scenes/returns';
import Returns from './scenes/cash';
import Vehicles from './scenes/vehicles';
import Locations from './scenes/locations';
import VanRunsLive from './scenes/vanrunslive';
import ActiveRuns from './scenes/activeruns';
import CompletedRuns from './scenes/completedruns';
import DeletedRuns from './scenes/deletedruns';
import News from './scenes/news';
import SignInSide from './scenes/signinside';
// import Bar from './scenes/bar';
// import Form from './scenes/form';
// import Line from './scenes/line';
// import Pie from './scenes/pie';
// import FAQ from './scenes/faq';
// import Geography from './scenes/geography';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import Cdecline from './scenes/cdecline';
import Csellopp from './scenes/csellopp';
// import Calendar from './scenes/calendar/calendar

function AppLayout() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <App />
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

function App() {
  const [isLogged, setIsLogged] = useState(false);
  const [userObj, setUserObj] = useState([]);
  const [userToken, setUserToken] = useState('');
  const [apir, setApir] = useState('');
  const [cid, setCid] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [brandingBlob, setBrandingBlob] = useState(null);

  if (!isLogged) return (
    <SignInSide userObj={userObj} setUserObj={setUserObj} apir={apir} setUserToken={setUserToken} setApir={setApir} cid={cid} setCid={setCid} brandingBlob={brandingBlob} setBrandingBlob={setBrandingBlob} setIsLogged={setIsLogged} isLogged={isLogged} setCompanyName={setCompanyName} />
  )

  return (
    <>
      <Sidebar userObj={userObj} apir={apir} setUserToken={setUserToken} setIsLogged={setIsLogged} companyName={companyName} brandingBlob={brandingBlob} setBrandingBlob={setBrandingBlob} />
      <main className="content">
        {/* <Topbar setIsLogged={setIsLogged} isLogged={isLogged} /> */}
        <Routes>
          <Route exact path="/" element={<Dashboard userToken={userToken} setUserToken={setUserToken} cid={cid} apir={apir} />} />
          <Route exact path="/pod" element={<Pod userToken={userToken} apir={apir} />} />
          <Route exact path="/cash" element={<Returns userToken={userToken} apir={apir} />} />
          <Route exact path="/returns" element={<Cash userToken={userToken} apir={apir} />} />
          <Route exact path="/vanrunslive" element={<VanRunsLive userToken={userToken} apir={apir} />} />
          <Route exact path="/pgdrift" element={<Pgdrift />} />
          <Route exact path="/cspent" element={<Cspent />} />
          <Route exact path="/news" element={<News userToken={userToken} setUserToken={setUserToken} userObj={userObj} apir={apir} />} />
          <Route exact path="/csellopp" element={<Dashboard userToken={userToken} />} />
          <Route exact path="/vehicles" element={<Vehicles userToken={userToken} apir={apir} />} />
          <Route exact path="/activeruns" element={<ActiveRuns userToken={userToken} apir={apir} />} />
          <Route exact path="/completedruns" element={<CompletedRuns apir={apir} />} />
          <Route exact path="/deletedruns" element={<DeletedRuns userToken={userToken} apir={apir} />} />
          <Route exact path="/locations" element={<Locations userToken={userToken} apir={apir} />} />
        </Routes>
      </main>
    </>
  );
}

export default AppLayout;